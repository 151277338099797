<template>
  <div class="loading-container">
    <div class="loading-text">Loading...</div>
    <img src="@/assets/Loading.gif" alt="Loading..." class="loading-gif" />
  </div>
</template>

<script>
export default {
  name: "LoadingVue",
};
</script>

<style scoped>
.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* 画面全体の高さ */
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.loading-text {
  color: rgb(3, 3, 3);
  /* テキストのサイズを調整 */
  font-size: 24px;
  /* テキストとGIFの間にスペースを追加 */
  margin-bottom: 5px;
}

.loading-gif {
  /* GIFの幅を100%に設定(レスポンシブ対応) */
  max-width: 100%;
  /* 高さを自動調整 */
  height: auto;
}
</style>
