<template>
  <div class="chat-container">
    <!--サイドバー表示ボタン-->
    <button class="threebaricon" v-if="!showSideBar" @click="judgeSideBarPush">
      <img src="@/assets/ThreeBar.png" alt="ThreeBar.png" class="threebar-icon" />
    </button>

    <!--ChatBotタイトル-->
    <h2 class="title">営農支援AI(仮称)</h2>
    <!--サインアウトボタン-->
    <button class="signoutbtn" @click="logout">サインアウト</button>

    <!--入力された文字列と応答を画面に出力-->
    <div class="message-container" ref="messageContainer">
      <!--「SideBar.vue」からのトリガーで発動される関数を定義-->
      <!--サイドバーコンポーネントを表示する条件に基づいて表示・非表示を切り替える-->
      <!--サイドバーの「閉じる」ボタンが押されたときには、closeSidebarメソッドが呼び出されてサイドバーが非表示になる-->
      <SideBar
        @session-selected="handleSesstionSelect"
        @loading-finished="setLoadingFalse"
        v-if="showSideBar"
        @close-sidebar="closeSidebar"
        @show-confirm="showConfirmDialog"
        @session-backup="getSessionId"
        @initialize-false="initializeFalse"
        @index-backup="indexBackup"
        ref="sidebar"
        :isInitialize="isInitialize"
        :conversation_id="conversation_id"
        :activeindex_backup="activeindex_backup"
        :userId="userId"
        :idToken="idToken"
        :last_login_time="last_login_time"
        :loading="loading"
        :isLoading="isLoading"
      />
      <!--チャット履歴削除画面の表示判定-->
      <ConfirmDialog
        v-if="isDialogVisible"
        @close="closeConfirmDialog"
        @delete-chat="deleteSession"
      />

      <!--Loading画面の表示判定(あくまで表示するだけ)-->
      <LoadingVue
        v-if="isLoading"
      />

      <!-- メッセージリストをループで表示 -->
      <div v-for="(message, index) in messages" :key="index">
        <!--userモードの時-->
        <!--ユーザーエリアにマウスを持っていくと、ゴミ箱ボタンを表示(ユーザーエリア以外にマウスを持っていくと、ゴミ箱ボタンを非表示にする)-->
        <div
          v-if="message.question"
          :class="['message', message.question.type]"
          @mouseenter="showButton(index)"
          @mouseleave="hideButton(index)"
        >
          <div class="messageicon-container">
            <!--userアイコンを表示-->

            <!--AIからの回答が返ってきている場合-->
            <!--ユーザーからの回答が返ってきている場合-->
            <img
              :src="getIcon(message.question.type)"
              class="message-icon"
              :alt="message.question.type + ' icon'"
            />
            <!--ゴミ箱ボタンを配置(ユーザー側のみ)-->
            <button
              class="trashicon"
              v-show="showTrashButton[index]"
              @click="deleteSomeConversationData(index)"
            >
              <img src="@/assets/TrashBox.png" alt="TrashBox.png" class="trash-icon" />
            </button>
          </div>

          <!--メッセージ表示部分-->
          <!--userモードの時-->
          <div class="message-text">{{ formatMessage(message.question.text) }}</div>
        </div>

        <!--botモードの時-->
        <!--ユーザーエリアにマウスを持っていくと、ゴミ箱ボタンを表示(ユーザーエリア以外にマウスを持っていくと、ゴミ箱ボタンを非表示にする)-->
        <div v-if="message.answer" :class="['message', message.answer.type]">
          <div class="messageicon-container">
            <!--botアイコンを表示-->
            <img
              :src="getIcon(message.answer.type)"
              class="message-icon"
              :alt="message.answer.type + ' icon'"
            />
          </div>

          <!--メッセージ表示部分-->

          <!--botのメッセージを表示-->
          <div v-if="loading && message == messages[messages.length - 1]" class="loader">
            <img src="@/assets/Loading.gif" alt="Loading..." />
          </div>
          <!--メッセージを表示-->
          <div v-else class="message-text">
            <div>{{ formatMessage(message.answer.text) }}</div>
            <!--RAGから返ってきた画像URLを1つずつ読み込み-->
            <div v-if="message.imageUrl">
              <div v-for="(url, index) in message.imageUrl" :key="index">
                <br />
                <img :src="url" alt="rag_image_photo" class="image-size" />
              </div>
            </div>

            <!--グラフ情報が返ってきている会話セットのみ、グラフを描画-->
            <div v-if="message.options">
              <br />

              <!--グラフ種別選択用ボタン-->
              <div class="dropdown">
                <button @click="toggleDropDown(index)">
                  {{ currentChartTypeLabel(message.graphType) }}
                </button>
                <ul v-if="isDropDownOpen[index]">
                  <li @click="updateChart(index, 'line')">折れ線グラフ</li>
                  <li @click="updateChart(index, 'bar')">縦棒グラフ</li>
                  <li @click="updateChart(index, 'pie')">円グラフ</li>
                </ul>
              </div>

              <apexChart
                :key=" message.graphType + index"
                :type="message.graphType"
                height="400"
                :options="message.options"
                :series="message.series"
              >
              </apexChart>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--メッセージ入力フォーム-->
    <div class="generalformposition">
      <div class="input-container">
        <textarea
          placeholder="AIにメッセージを送信する"
          v-model="newMessage"
          @keydown="handleKeydown"
          @input="checkTextLength"
          id="textarea"
          required
        >
        </textarea>
        <!--メッセージ送信ボタン(飛行機アイコン仕様)-->
        <button class="sendbtn" @click="sendMessage">
          <img src="@/assets/PaperFly.png" alt="PaperFly.png" class="paperfly-icon" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ConfirmDialog from "./components/ConfirmDialog.vue";
import SideBar from "./components/SideBar.vue";
import VueApexCharts from "vue3-apexcharts";
import { ref, onMounted, nextTick } from "vue";
import msalInstance from "@/msalConfig";
import LoadingVue from "./components/LoadingVue.vue";

export default {
  components: {
    SideBar,
    ConfirmDialog,
    LoadingVue,
    apexChart: VueApexCharts,
  },
  setup() {
    const isAuthenticated = ref(false);
    // 初回ローディング画面、表示判定フラグ(初回はtrueにしてローディング表示画面をだすように設定)
    let isLoading = ref(true);
    const userName = ref("");
    const idToken = ref("");
    // ユーザーID格納用の変数を用意
    const userId = ref("");
    const sidebar = ref("");
    // 最終ログイン時刻格納用の変数を用意
    const last_login_time = ref("");

    // Azure Functions接続先(バックエンド)
    const function_ip = process.env.VUE_APP_API_URL;

    // ログアウトタイマーを管理する変数
    let logoutTimer = null;

    const login = async () => {
      const loginRequest = {
        // 使用するスコープを指定
        scopes: ["user.read"],
        // ここを追加することで、毎回サインイン要求を強制
        // prompt: "login"
      };

      try {
        msalInstance.loginRedirect(loginRequest);
      } catch (err) {
        console.error(err);
        isAuthenticated.value = false;
      }
    };

    const logout = async () => {
      // 前のタイマーが走っていればクリア
      if (logoutTimer) {
        clearTimeout(logoutTimer);
      }

      // 現在、ログイン中のアカウント情報を取得
      const accountFilter = {
        homeAccountId: userId.value
      };
      const currentAccount = msalInstance.getAccount(accountFilter);

      // 取得したアカウントで強制ログアウト(どのアカウントをログアウトしますか？表示はさせない)
      await msalInstance.logoutRedirect({account: currentAccount});

      isAuthenticated.value = false;
      // ユーザー名をリセット
      userName.value = "";
      // IDトークンをリセット
      idToken.value = "";
      // ユーザーIDをリセット
      userId.value = "";

      // 全てのセッションstorage情報をクリア
      sessionStorage.clear();
      // Cookieを削除
      // ※有効期限を昔の日付に設定することで削除される
      document.cookie = "user_token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
      
      // ページをリロードして再認証できるようにする
      window.location.reload();
    };

    const handleAuthentication = async () => {
      try {
        // MSALの初期化を待機
        await msalInstance.initialize();

        const response = await msalInstance.handleRedirectPromise();

        if (response) {
          isAuthenticated.value = true;
          userName.value = response.account.name;
          idToken.value = response.account.idToken;
          // ユーザーIDを取得
          userId.value = response.account.homeAccountId;

          // 次のマイクロタスクで呼び出す
          // ※ユーザーIDが変数に格納されてからでないと、会話履歴を取得できないため
          await nextTick();
          // CSRFトークン、IDトークンの取得・会話履歴の読み込み(「200」OKのみ実施)
          getCsrfToken(userName.value, userId.value);
        } else {
          const accounts = msalInstance.getAllAccounts();
          if (accounts.length > 0) {
            isAuthenticated.value = true;
            userName.value = accounts[0].name;
            idToken.value = accounts[0].idToken;
            // ユーザーIDを取得
            userId.value = accounts[0].homeAccountId;

            // 次のマイクロタスクで呼び出す
            // ※ユーザーIDが変数に格納されてからでないと、会話履歴を取得できないため
            await nextTick();
            // CSRFトークンの取得・会話履歴の読み込み(「200」OKのみ実施)
            getCsrfToken(userName.value, userId.value);
          } else {
            // アカウントが見つからない場合、ログインを開始
            await login();
          }
        }
      } catch (err) {
        console.error(err);
        isAuthenticated.value = false;
      }
    };

    // ログアウトタイマーを開始する
    const startLogoutTimer = () => {
      // 60分 (3600000ms) 後にログアウトを実行
      if (logoutTimer) {
        // 前のタイマーをクリア
        clearTimeout(logoutTimer);
      }
      logoutTimer = setTimeout(() => {
        logout();
        // console.log("無操作状態で60分経過しました");
      }, 3600000);
    };

    // ユーザーのアクションを監視してタイマーをリセット
    const resetLogoutTimer = () => {
      // タイマーを再起動
      startLogoutTimer();
      // console.log("ユーザーのアクションを検知しました\nタイマーを再起動します");
    };

    // ユーザーのアクションを検知
    // マウスを動かす or マウスクリック or キー入力でタイマーをリセット後、タイマーを再起動する
    const initializeTimerReset = () => {
      const events = ["mousemove", "keydown", "click"];
      events.forEach((event) => {
        window.addEventListener(event, resetLogoutTimer);
      });
    };

    // CSRFトークン、IDトークンの取得、前回ログイン日時の抽出(200OKのみ、実行、初回処理)
    const getCsrfToken = async (userName, userId) => {
      // バックエンドの接続先
      const url = function_ip + "&action=GetCSRFToken";

      // ログイン中のユーザーIDをオブジェクトとして定義
      const dataToSend = {
        // ログイン中のユーザー名とIDをセット
        userName: userName,
        userId: userId,
      };

      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // IDトークンをヘッダーに含める
            Authorization: `Bearer ${idToken.value}`,
          },
          body: JSON.stringify(dataToSend),
        });

        const data = await response.json();
        // サーバーからのレスポンスに含まれるCSRFトークン
        const csrfToken = data.csrfToken;
        // サーバーからのレスポンスに含まれるユーザートークン
        const user_token = data.userToken;
        // console.log("バックエンド側から取得したユーザーIDごとに生成されたユーザートークン", user_token);

        // サーバーからのレスポンスに含まれる最終ログイン日時
        last_login_time.value = data.last_login_time;

        // セッションストレージにCSRFトークンを保存
        sessionStorage.setItem("csrfToken", csrfToken);

        // Cookieにユーザートークンを保存
        document.cookie = `user_token=${user_token}; path=/; Secure; SameSite=strict`;
        // console.log("Cookieの値をセットしました");

        // ユーザーIDに紐づいた会話履歴の抽出
        sidebar.value.fetchConversations();
      } catch (error) {
        alert("通信エラーが発生しました。接続先を確認してください。");

        // 通信エラー時には、初回ローディング表示画面を非表示にする
        isLoading.value = false;

        console.error("CSRFトークンの取得に失敗しました", error);
      }
    };

    onMounted(() => {
      // 初回、ログイン処理を実行
      handleAuthentication();

      // ログインしたら60分後にログアウトするタイマーを設定
      // 初回起動時、何も操作されていない状態のタイマー開始
      // ※ユーザーが操作するたびに、タイマーがリセット・再起動される
      startLogoutTimer();
      // ユーザーのアクションの検知開始
      initializeTimerReset();
      // console.log("タイマースタート");
    });

    return {
      isAuthenticated,
      userName,
      idToken,
      // ユーザーIDを返す
      userId,
      logout,
      sidebar,
      last_login_time,
      isLoading,
    };
  },
  data() {
    return {
      // メッセージ入力フォームで入力された文字列
      newMessage: "",
      // tsuzumiからのレスポンス格納用文字列
      responce: "",
      // メッセージのリスト
      messages: [],
      // 1行に入力可能な文字数
      maxCharsPerLine: 40,
      // メッセージ入力フォーム要素格納用変数
      getTextarea: null,
      // サイドバー表示用フラグ(falseの場合、サイドバーを非表示)
      showSideBar: true,
      // ゴミ箱ボタン表示判定フラグ(message.typeがuser側のみ表示するようにする)
      showTrashButton: {},
      // ぐるぐるGIF表示フラグ(初回はfalseで非表示設定)
      loading: false,
      // 会話セッションIDの格納用変数
      conversation_id: null,
      // チャット履歴確認画面表示判定フラグ
      isDialogVisible: false,
      // 初回データ読み込み判定フラグ
      isInitialize: true,
      // サイドバーボタンで選択中のインデックス(バックアップ用)
      activeindex_backup: 0,
      // バックエンドから受け取ったURL格納用リスト
      imageUrl: [],
      // ApexChartsの設定
      chart: {
        // 各データからグラフ生成時に毎度追加される配列(複数グラフ描画のために必要)
        graphArrayData: [],
      },
      // ApexChartsに描画する各座標
      x_value: "",
      y_value: "",
      // グラフ生成時に使用するパラメーター(初回は-1で初期化)
      graphIndex: -1,
      // グラフデータ、存在チェック
      isGraphData: false,
      // 各メッセージのドロップダウン状態を初期化
      isDropDownOpen: [false, false, false],
      // サイドバーボタン押下時に読み取るメッセージ格納用リスト
      // (ローカル変数からグローバル変数に変更)
      chatMessages: [],
      // 今作のスタート期間
      period_of_seedlings_made_start: "2024/8/22",
      // 今作のファイナル期間
      period_of_seedlings_made_final: "2025/1/15",

      // Azure Function接続先IP(バックエンド)
      function_ip: process.env.VUE_APP_API_URL,
    };
  },

  mounted() {
    // textareaの要素を取得(初回のみ)
    this.getTextarea = document.getElementById("textarea");
  },
  methods: {
    // ローディングを非表示にするメソッド
    setLoadingFalse() {
      this.isLoading=false;
      // console.log("ローディング表示画面を非表示にします");
    },
    // サイドバーボタン押下判定
    // サイドバーが閉じているとき、リクエスト中(ぐるぐる表示中)はサイドバーボタンを押下してもサイドバーが表示されないようにする
    judgeSideBarPush()
    {
      // ローディング中はサイドバーボタン押下不可能
      if(this.loading)
      {
        // console.log("リクエスト中のため、サイドバーをだせません");
        return;
      }
      // そうでない場合にはサイドバーボタン押下でサイドバーを出現
      else
      {
        this.showSideBar = true;
        // console.log("リクエストが返ってきているため、サイドバーを表示");
      }
    },
    // グラフ種別選択ボタンのラベル表記の制御
    currentChartTypeLabel(graphType) {
      return graphType == 'line' ? '折れ線グラフ' : 
             graphType == 'bar' ? '縦棒グラフ' : '円グラフ';
    },
    // グラフ種別選択ボタン押下時のトグル制御
    toggleDropDown(index) {
      // ローディング中ではない場合のみ、グラフ種別押下可能とする
      if(!this.loading)
      {
        this.isDropDownOpen[index] = !this.isDropDownOpen[index]; 
      }
    },
    // グラフ種別選択時のボタン表示制御
    async updateChart(index, type) {
      // messagesのgraphTypeを更新
      this.messages[index].graphType = type;
      // console.log("格納済のchatMessages", this.chatMessages[index - 1]);
      // console.log(type);

      // 変更した「type」でグラフを再描画
      await this.repaintGraph();

      // ドロップダウンを閉じる
      this.isDropDownOpen[index] = false;
    },
    callFetchConversations() {
      this.$refs.sidebar.fetchConversations();
    },
    // ユーザー側で入力されたメッセージを画面に出力後、クエリを送信
    sendMessage() {
      // 何も入力されていない場合は、メッセージを送信しない
      if (this.newMessage.trim() === "" || this.loading) {
        // console.log("入力文字列が空 or ローディング中のためメッセージの送信不可能");

        return;
      }

      // メッセージを処理するロジック

      // ユーザーのメッセージをリストに追加
      this.messages.push({
        question: { text: this.newMessage, type: "user" },
        answer: null,
        imageUrl: null,
      });

      // ユーザーが入力したメッセージをバックアップ
      const userMessage = this.newMessage;

      // データの変更が反映された後に、コールバックを実施
      this.$nextTick(() => {
        // 最下部まで自動スクロール
        this.scrollMessageContainer();
        // 入力したメッセージをAzure AI Search、LLMへ投げる
        this.replyToMessage(userMessage);
      });
      // 入力内容をクリア
      this.userMessage = "";
      // キーボード入力内容とサイズをリセット
      this.resetTextArea();
    },
    // ユーザーが投げたメッセージから、全角文字を半角文字に変換
    convertToHalfWidth(input) {
      // console.log("全角文字があれば、半角文字に直します");

      return input.replace(/[０-９]/g, (match) =>
          String.fromCharCode(match.charCodeAt(0) - 0xFEE0))
          .replace(/[Ａ-Ｚ]/g, (match) =>
          String.fromCharCode(match.charCodeAt(0) - 0xFEE0))
          .replace(/[ａ-ｚ]/g, (match) =>
          String.fromCharCode(match.charCodeAt(0) - 0xFEE0))
          .replace(/（/g, '(')
          .replace(/）/g, ')')
          .replace(/／/g, '/')
          .replace(/％/g, '%')
          .replace(/＃/g, '#');
    },
    // ユーザー側で入力されたメッセージを受け取り、回答を返す
    async replyToMessage(userMessage) {
      // Azure Funcstionsの接続先を指定
      const api_url = this.function_ip + "&action=PostPromptToAzure";

      // ローディングを開始
      this.loading = true;

      // 一時的に空文字をリストの末尾に追加(ぐるぐる表示をしたいため)
      setTimeout(() => {
        this.messages.push({
          question: null,
          answer: { text: "", type: "bot" },
          imageUrl: null,
        });

        // データの変更が反映された後に、コールバックを実施
        this.$nextTick(() => {
          // 最下部まで自動スクロール
          this.scrollMessageContainer();
        });
      }, 0);

      // 「ユーザーID」と「ユーザーが投げた質問」、「会話セッションID」をjsonにしてPythonに投げる

      // PythonのAPIを呼び出し、プロンプトを投げる

      // ユーザーメッセージ中に、全角文字があれば半角文字に変換
      const convetedUserMessage = this.convertToHalfWidth(userMessage);
      // console.log("全角→半角変換後の文字列", convetedUserMessage);
      
      // 選択中の会話セッションIDと、ユーザーメッセージをオブジェクトとして定義
      const dataToSend = {
        // 選択中の会話セッションIDをセット
        chat_session_id: this.conversation_id,
        // ユーザーが投げた質問をセット
        question_text: convetedUserMessage,
      };

      // セッションストレージからCSRFトークンを取得
      const csrfToken = sessionStorage.getItem("csrfToken");
      // ユーザートークンをCookieから取得
      const user_token = this.$cookies.get("user_token");
      // console.log("Cookieの値をゲットしました");

      try {
        // バックエンドにリクエストを送る
        const response = await fetch(api_url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // IDトークンをヘッダに含める
            Authorization: `Bearer ${this.idToken}`,
            // CSRFトークンをヘッダーに含める
            "X-CSRF-TOKEN": csrfToken,
            // ユーザーIDをヘッダーに含める
            "X-USER-ID": this.userId,
            // ユーザーIDごとに振り出されたユーザートークンをヘッダーに含める
            "X-USER-TOKEN": user_token,
          },
          body: JSON.stringify(dataToSend),
        });
        // APIからのレスポンスをjson形式で取得する
        const answer = await response.json();

        // APIから返ってきたjsonデータのうち、resultのみを画面に出力させるための変数に代入
        this.responce = answer.result;
        // 画像URLリストを取得(SASトークン付き)
        this.imageUrl = answer.insite_urls;

        //それぞれの情報をログに出力
        console.log("天気APIからの情報", answer.weather);
        console.log("DBからの情報", answer.db);
        console.log("RAGからの情報", answer.rag);
        console.log("RAGからの画像URL", answer.urls);
        console.log("tsuzumiからの回答", answer.result);
        // console.log("グラフ種別判断", answer.judge_graphtype);

        // 逆質問パターンではない、かつDBデータが返ってきた場合のみ、DBからの情報を受け取る
        // ※CosmosDBから返ってくるデータの配列の長さが1つの場合にはグラフを表示させない(2つ以上でグラフを表示)
        if (answer.db != answer.result && (answer.db != "" && answer.db.length > 1)) {
          // console.log("グラフを出力");
          // グラフをセット
          this.setGraph(answer.judge_graphtype);

          // グラフ表示用の変数に値をセット(あくまでグラフ描画の準備だけ)
          this.prepareChartData(answer.db, answer.judge_graphtype, userMessage);
          // グラフデータ確認フラグをオン
          this.isGraphData = true;
        } else {
          // console.log("グラフを出力しない");
          // グラフデータ確認フラグをオフ
          this.isGraphData = false;
        }
        // ローディング表示を終了、メッセージを画面に出力
        this.addResult(answer.judge_graphtype);

        // サイドバーが表示されていた場合は、そのまま最新のデータを読み込む
        if (this.showSideBar) {
          // SideBar.vueのfetchConversationsメソッドを呼び出す
          this.$refs.sidebar.fetchConversations();
        }
      } catch (error) {
        //通信エラー時
        console.error(error);

        const errorMessage = "通信エラーが発生しました。接続先を確認してください。";
        // エラーメッセージを代入
        this.responce = errorMessage;

        // グラフデータ確認フラグをオフ
        this.isGraphData = false;

        // ローディング表示を終了、エラーメッセージを画面に出力
        this.addResult("");
      }
    },
    // 結果を画面に表示させるためのリストの変数に追加
    addResult(graphType) {
      // ローディングを終了
      this.loading = false;

      // リストの末尾に追加した空文字を削除後、再度リストの末尾にサーバーからの応答を追加
      // ※この処理を毎度メッセージが入力されサーバーからの応答が返ってくるたびに実施する
      this.messages.pop();
      // console.log(this.messages);

      // LLMから返ってきた応答 or エラーメッセージを末尾に追加
      setTimeout(() => {
        if (this.isGraphData) {
          this.messages.push({
            question: null,
            answer: { text: this.responce, type: "bot" },
            imageUrl: this.imageUrl,
            // バックエンドから返ってきたグラフの種別をセット
            graphType: graphType,
            // グラフ描画の際のX軸の値をセット(値が返ってきていない場合はnullでセット)
            options: this.chart.graphArrayData[this.graphIndex].options,
            // グラフ描画の際のY軸の値をセット(値が返ってきていない場合はnullでセット)
            series: this.chart.graphArrayData[this.graphIndex].series,
          });
          // console.log("グラフデータあり");
        } else {
          this.messages.push({
            question: null,
            answer: { text: this.responce, type: "bot" },
            imageUrl: this.imageUrl,
            // グラフの種別をnullでセット
            graphType: "",
            // X軸の値をnullでセット
            options: "",
            // Y軸の値をnullでセット
            series: "",
          });
          // console.log("グラフデータなし");
        }
        // console.log("this.messages", this.messages);

        // 取得したtsuzumiからの回答をクリア
        this.responce = "";
        // 取得した画像URL(ローカル)をクリア
        this.imageUrl = [];

        // データの変更が反映された後に、コールバックを実施
        this.$nextTick(() => {
          // 最下部まで自動スクロール
          this.scrollMessageContainer();
        });
      }, 0);
    },
    // アイコンのパスより、アイコン画像を返す
    getIcon(type) {
      if (type === "user") {
        // ユーザーアイコンのパス
        return require("@/assets/People.png");
      } else if (type === "bot") {
        // ボットアイコンのパス
        return require("@/assets/Robot.png");
      }
    },
    // 返されたテキストの文字列の型チェック
    formatMessage(text) {
      // tsuzumiから返ってきた応答が文字列でない場合は文字列に変換

      let processedText;

      // 文字列がどうか、確認
      if (typeof text === 'string') 
      {
        // 文字列の場合はそのまま
        processedText = text;
        // console.log("文字列が返ってきているため、そのまま渡します");
      } 
      else 
      {
        // それ以外の場合はJSON文字列に変換
        processedText = JSON.stringify(text);
        // console.log("文字列ではないため、JSON文字列に変換します");
        // console.log("JSON変換後の文字列", processedText);
      }

      return processedText;
    },
    // 押下されたキーによって、イベントを制御
    handleKeydown(event) {
      if (event.key === "Enter" && !event.shiftKey) {
        // デフォルトのEnterキー動作を防止（改行の防止）
        event.preventDefault();
        // メッセージを送信
        this.sendMessage();
      } else if (event.key === "Enter" && event.shiftKey) {
        // Shift + Enterの場合は何もしない（改行を挿入)
      }
    },
    // 入力された文字数によって、自動的に改行を行う(1行につき最大40文字、これを超えると自動で改行)
    // textareaの高さを自動調整
    checkTextLength() {
      let text = this.newMessage;
      // 文字列が入力される度に配列に追加、改行された場合に文字列を分割
      let lines = text.split("\n");

      for (let i = 0; i < lines.length; i++) {
        if (lines[i].length > this.maxCharsPerLine) {
          // 行の末尾から改行を挿入する
          let splitLine = lines[i].match(
            new RegExp(".{1," + this.maxCharsPerLine + "}", "g")
          );
          // 現在の行を分割して置き換え
          lines.splice(i, 1, ...splitLine);
        }
      }

      // 改行を挿入して textarea に反映
      this.newMessage = lines.join("\n");

      // textareaの高さを自動調整
      this.resizeTextarea();
    },
    // メッセージ入力フォームで改行される際のCSS高さを自動調整
    resizeTextarea() {
      // 高さをリセットしてから、内容に基づいて高さを再計算
      this.getTextarea.style.height = "auto";
      const newHeight = this.getTextarea.scrollHeight;
      this.getTextarea.style.height = newHeight + "px";
    },
    // メッセージ入力フォームのサイズリセット
    resetTextArea() {
      // 元のサイズにリセット
      if (this.getTextarea.style.height != "43px") {
        this.getTextarea.style.height = "43px";
      }
      // メッセージ内容クリア
      this.newMessage = "";
    },
    // メッセージ出力後の自動スクロール処理
    scrollMessageContainer() {
      // メッセージ出力部分の要素を取得
      const container = this.$refs.messageContainer;
      // 現在の高さを取得して、スクロール位置を最下部に持っていく
      container.scrollTop = container.scrollHeight;
    },
    // サイドバー非表示フラグ制御
    closeSidebar() {
      // サイドバーを閉じる
      this.showSideBar = false;
    },
    // ゴミ箱ボタン表示判定フラグ(マウスオーバー時)
    showButton(zoneId) {
      // ローディング中はごみ箱ボタン押下不可能
      if(this.loading)
      {
        // console.log("ローディング中のため、ごみ箱ボタンは出現しません");
        return;
      }
      else
      {
        // ボタン表示判定フラグをオンにして、ゴミ箱ボタンを表示(message.typeがtrueの場合のみ)
        this.showTrashButton[zoneId] = true;
      }
    },
    // ゴミ箱ボタン表示判定フラグ(マウスオーバーしていない時)
    hideButton(zoneId) {
      // ボタン表示判定フラグをオフにして、ゴミ箱ボタンを非表示
      this.showTrashButton[zoneId] = false;
    },
    // フィルタリングされたメッセージをリストに格納、会話セッションIDの取得
    handleSesstionSelect(returnMessages, sesstionid) {
      // SideBar.vueから送られてきたセッションIDごとのデータを受け取る
      this.chatMessages = returnMessages;
      // console.log("更新後のchatMessages", this.chatMessages);

      // クリックされたサイドバーボタンの会話セッションIDを取得
      this.conversation_id = sesstionid;
      // console.log("選択された会話セッションID", this.conversation_id);

      // 一度、初回のデータを全て削除
      this.messages.splice(0);
      // グラフデータ格納用のリストとそのリストインデックスをリセット
      this.resetGraphArray();

      // 初回のデータをmessagesに代入
      this.messages.push({
        question: null,
        answer: { text: "質問したい事項を入力してください。", type: "bot" },
        imageUrl: null,
        // バックエンドから返ってきたグラフの種別をnullでセット
        graphType: null,
        // X軸の値をnullでセット
        options: null,
        // Y軸の値をnullでセット
        series: null,
      });
      // console.log("1番目に追加された初回データ", this.messages);
      // console.log(chatMessages);

      // 会話履歴データが存在する場合のみ、リストに追加
      if (this.chatMessages != "") {
        // チャット画面に表示するために、CosmosDBから取得したデータを1つずつmessages(リスト型)に代入
        this.chatMessages.forEach((msg) => {
          // グラフ描画用のデータが格納されている場合はグラフのデータをセット
          // ※CosmosDBから返ってくるデータの配列の長さが1つの場合にはグラフを表示させない(2つ以上でグラフを表示)
          if (msg.graphData != null && msg.graphData.length > 1) {
            // グラフ描画の準備

            // グラフをセット
            this.setGraph(msg.graphType);
            // グラフ表示用の変数に値をセット(あくまでグラフ描画の準備だけ)
            this.prepareChartData(msg.graphData, msg.graphType, msg.question.text);
            // console.log("グラフの座標セット完了");

            // 元々のチャットデータを画面表示用のリストに追加
            this.messages.push(msg);
            // console.log("リストに追加途中のメッセージリスト", this.messages);
            // console.log("リストに追加完了");

            // グラフのデータを画面表示用のリストの同じ要素に再追加
            // グラフ描画の際のX軸の値をセット(値が返ってきていない場合はnullでセット)
            msg.options = this.chart.graphArrayData[this.graphIndex].options;
            // グラフ描画の際のY軸の値をセット(値が返ってきていない場合はnullでセット)
            msg.series = this.chart.graphArrayData[this.graphIndex].series; 
            // console.log("座標の指定完了");
            // console.log("座標指定完了時のグラフインデックス", this.graphIndex);

            // console.log("グラフデータが存在します");
          }
          // 返ってきたデータの中にグラフデータが存在しない場合には、そのままグラフデータ以外をリストに追加
          else {
            // 元々のチャットデータを画面表示用のリストに追加
            this.messages.push(msg);
            // console.log("グラフデータが存在しません");
          }
        });
        // console.log("2番目以降に追加されたデータ", this.messages);
        // console.log("処理完了、現在の配列の中", this.messages);
        // console.log("リスト追加後の中身", this.chart.graphArrayData);
        // console.log("今のセッションで生成中のグラフの個数", this.graphIndex + 1);
      }
      // 初回、Loading画面を非表示にする
      this.setLoadingFalse();
      // console.log("データの読み込み完了");
    },
    // グラフの再描画(グラフ種別判断ボタン用)
    repaintGraph()
    {
      // 一度、初回のデータを全て削除
      this.messages.splice(0);
      // グラフデータ格納用のリストとそのリストインデックスをリセット
      this.resetGraphArray();

      // 初回のデータをmessagesに代入
      this.messages.push({
        question: null,
        answer: { text: "質問したい事項を入力してください。", type: "bot" },
        imageUrl: null,
        // バックエンドから返ってきたグラフの種別をnullでセット
        graphType: null,
        // X軸の値をnullでセット
        options: null,
        // Y軸の値をnullでセット
        series: null,
      });

      // console.log("再描画の設定をする前のchatMessages", this.chatMessages);

      // 会話履歴データが存在する場合のみ、リストに追加
      if (this.chatMessages != "") {
        // チャット画面に表示するために、CosmosDBから取得したデータを1つずつmessages(リスト型)に代入
        this.chatMessages.forEach((msg) => {
          // グラフ描画用のデータが格納されている場合はグラフのデータをセット
          // ※CosmosDBから返ってくるデータの配列の長さが1つの場合にはグラフを表示させない(2つ以上でグラフを表示)
          if (msg.graphData != null && msg.graphData.length > 1) {
            // グラフ描画の準備

            // グラフをセット
            this.setGraph(msg.graphType);
            // console.log("グラフのリスト、追加完了");
            // グラフ表示用の変数に値をセット(あくまでグラフ描画の準備だけ)
            this.prepareChartData(msg.graphData, msg.graphType, msg.question.text);
            // console.log("グラフの描画用の座標に代入完了");

            // 元々のチャットデータを画面表示用のリストに追加
            this.messages.push(msg);
            // console.log("元々のチャットデータを画面表示用のリストに追加", this.messages);

            // グラフのデータを画面表示用のリストの同じ要素に再追加
            // グラフ描画の際のX軸の値をセット(値が返ってきていない場合はnullでセット)
            msg.options = this.chart.graphArrayData[this.graphIndex].options;
            msg.options.chart.type = msg.graphType;
            // グラフ描画の際のY軸の値をセット(値が返ってきていない場合はnullでセット)
            msg.series = this.chart.graphArrayData[this.graphIndex].series;
            // console.log("グラフ用のデータをリストに再追加");
            // console.log("messagesの全リスト", this.messages);
          }
          // 返ってきたデータの中にグラフデータが存在しない場合には、そのままグラフデータ以外をリストに追加
          else {
            // 元々のチャットデータを画面表示用のリストに追加
            this.messages.push(msg);
          }
        });
      }
      // console.log("グラフを再描画します");
    },
    // チャット履歴削除画面を表示
    showConfirmDialog() {
      this.isDialogVisible = true;
    },
    // チャット履歴削除画面を非表示
    closeConfirmDialog() {
      this.isDialogVisible = false;
    },
    // 「SideBar.vue」からセッションIDを受け取る
    getSessionId(sessionId) {
      // セッションIDをバックアップ
      this.conversation_id = sessionId;
      // console.log("セッションIDを受け取りました", this.conversation_id);
    },
    // ログイン中のユーザーID、セッションIDとセットで登録されている会話データを全て削除
    deleteSession() {
      // バックエンドの接続先を指定
      const api_url = this.function_ip + "&action=DeleteConversation";

      // セッションストレージからCSRFトークンを取得
      const csrfToken = sessionStorage.getItem("csrfToken");
      // ユーザートークンをCookieから取得
      const user_token = this.$cookies.get("user_token");
      // console.log("Cookieの値をゲットしました");

      fetch(api_url, {
        method: "POST",
        headers: {
          // JSON形式で送信
          "Content-Type": "application/json",
          // IDトークンをヘッダに含める
          Authorization: `Bearer ${this.idToken}`,
          // CSRFトークンをヘッダーに含める
          "X-CSRF-TOKEN": csrfToken,
          // ユーザーIDをヘッダーに含める
          "X-USER-ID": this.userId,
          // ユーザーIDごとに振り出されたユーザートークンをヘッダーに含める
          "X-USER-TOKEN": user_token,
        },
        body: JSON.stringify({
          // セッションIDをstring型に変換してリクエストボディに含める
          sessionId: this.conversation_id,
        }),
      })
        .then((response) => {
          if (response.ok) {
            alert("チャット履歴を削除しました。");

            // サイドバーが表示されている時のみ、最新のデータを読み込み表示を更新
            if (this.showSideBar) {
              // データ削除時には最新のデータを取得するために、再度フラグをtrueにする
              this.isInitialize = true;
              // サイドバーの情報を最新に更新
              // SideBar.vueのfetchConversationsメソッドを呼び出す
              this.$refs.sidebar.fetchConversations();
            }
          } else {
            alert("チャット履歴の削除が失敗しました。");
          }
        })
        .catch((error) => {
          alert("通信エラーが発生しました。接続先を確認してください。");
          console.error("Error:", error);
        });
    },
    // ゴミ箱ボタンで選択された一部の会話データのみを削除
    deleteSomeConversationData(index) {
      // console.log(index);

      // リクエスト時に送るインデックスを作成
      const repair_index = index - 1;
      // console.log("repair_index", repair_index);

      // バックエンドの接続先を指定
      const api_url = this.function_ip + "&action=DeleteSomeConversation";

      // セッションストレージからCSRFトークンを取得
      const csrfToken = sessionStorage.getItem("csrfToken");
      // ユーザートークンをCookieから取得
      const user_token = this.$cookies.get("user_token");
      // console.log("Cookieの値をゲットしました");

      fetch(api_url, {
        method: "POST",
        headers: {
          // JSON形式で送信
          "Content-Type": "application/json",
          // IDトークンをヘッダに含める
          Authorization: `Bearer ${this.idToken}`,
          // CSRFトークンをヘッダーに含める
          "X-CSRF-TOKEN": csrfToken,
          // ユーザーIDをヘッダーに含める
          "X-USER-ID": this.userId,
          // ユーザーIDごとに振り出されたユーザートークンをヘッダーに含める
          "X-USER-TOKEN": user_token,
        },
        body: JSON.stringify({
          // 修正後のインデックスをstring型に変換してリクエストボディに含める
          index: repair_index,
          // セッションIDをstring型に変換してリクエストボディに含める
          sessionId: this.conversation_id,
        }),
      })
        .then((response) => {
          if (response.ok) {
            alert("一部のチャット履歴を削除しました。");

            // サイドバーが表示されている時のみ、最新のデータを読み込み表示を更新
            if (this.showSideBar) {
              this.$refs.sidebar.fetchConversations();
            } else {
              // サイドバーが閉じている時はデータの自動更新ができないため、App.vue側のリストを削除
              this.deleteSomeListData(index);
            }
          } else {
            alert("一部のチャット履歴の削除が失敗しました。");
          }
        })
        .catch((error) => {
          alert("通信エラーが発生しました。接続先を確認してください。");
          console.error("Error:", error);
        });
    },
    // 初回表示フラグをfalseにする
    initializeFalse() {
      this.isInitialize = false;
    },
    // SideBar.vue側のインデックスのバックアップ
    indexBackup(index) {
      this.activeindex_backup = index;
      // console.log("インデックスをバックアップしました");
    },
    // 一部会話履歴の削除時における、ローカルでのリスト削除処理
    deleteSomeListData(index) {
      // 格納されているリストの内容によって、削除するインデックスを分岐

      // リストの中身として、質問と回答がセットで格納されている場合
      // ※つまり、ComosDBからデータを取得後、CosmosDBのリストの形で自動でデータ追加されたケースの場合
      if (this.messages[index].question && this.messages[index].answer) {
        // 対象のリスト1つのみを削除
        this.messages.splice(index, 1);
        // console.log("CosmosDBから取得したデータです");
        // console.log("一部データ削除後のリストデータ一覧", this.messages);
      }
      // questionのみ値が存在する場合
      // ※つまり、サイドバーを閉じた状態で新たにGUIからプロンプトが投げられた場合(question or answerのどちらかがnulになっている状態)
      else {
        // 対象のリスト2つ両方削除
        this.messages.splice(index, 2);
        // console.log("ローカルでリストに追加したデータです");
        // console.log("一部データ削除後のリストデータ一覧", this.messages);
      }
    },
    // グラフ作成用のデータを取得
    prepareChartData(data, graphType, userPrompt) {
      // console.log("Azure CosmosDBから受け取ったdata", data);
      // console.log("受け取ったgraphType", graphType);

      // バックエンドから返ってきたグラフ種別が「折れ線グラフ」の場合
      if(graphType == "line")
      {
        // console.log("これから折れ線グラフを出力");

        // 糖度データ問い合わせ確認(bed番号が返ってきている場合でも一緒に判断)
        // dataがnullではなく、配列である、かつ「date」「brix」「bed_number」が両方ともundefinedではない場合にtrueを返して折れ線グラフを生成する
        const hasBrixData = Array.isArray(data) && data.some(item => 
          item.date !== undefined && item.brix !== undefined && item.bed_number !== undefined
        );
        // console.log("hasBrixData", hasBrixData);

        // 計測データ問い合わせ確認
        // dataがnullではなく、配列である、かつ「date」「date_item_id_seed_id」が両方ともundefinedではない場合にtrueを返して折れ線グラフを生成する
        const hasMeasureData = Array.isArray(data) && data.some(item => 
          item.date !== undefined && item.date_item_id_seed_id !== undefined
        );
        // console.log("hasMeasureData", hasMeasureData);

        // 計測データ中の中でも「SPAD」データが入っている場合には、trueを返す
        const hasMeasureSpadData = Array.isArray(data) && data.some(item => 
          item.date !== undefined && item.date_item_id_seed_id !== undefined && item.quantity_4 !== undefined
        );
        // console.log("hasMeasureSpadData", hasMeasureSpadData);

        // 計測データ中の中でも、灌水量データ(before、afterの両方)が返ってきている場合には、trueを返す
        const hasMeasureIrrigationBeforeAfterData = Array.isArray(data) && data.some(item => 
          item.date != undefined && item.before != undefined && item.after != undefined
        );
        // console.log("hasMeasureIrrigationBeforeAfterData", hasMeasureIrrigationBeforeAfterData);

        // 計測データ中の中でも、灌水量データ(after)が返ってきている場合には、trueを返す
        const hasMeasureIrrigationAfterData = Array.isArray(data) && data.some(item => 
          item.date !== undefined && item.before == undefined && item.after !== undefined
        );
        // console.log("hasMeasureIrrigationAfterData", hasMeasureIrrigationAfterData);

        // 養液データのデータ問い合わせ確認
        const hasNutrientSolutionData = Array.isArray(data) && data.some(item => 
          item.date !== undefined && item.bed_number != undefined && 
          (item.ca != undefined || item.k != undefined || item.no3 != undefined || item.ph != undefined || item.ec != undefined)
        );
        // console.log("hasNutrientSolutionData", hasNutrientSolutionData);

        // 収穫量データの問い合わせ確認
        const hasCropYeildData = Array.isArray(data) && data.some(item => 
          item.date !== undefined && item.crop_yeild != undefined
        );
        // console.log("hasCropYeildData", hasCropYeildData);

        // 総収量データの問い合わせ確認
        const hasWeightData = Array.isArray(data) && data.some(item => 
          item.date !== undefined && item.class != undefined && item.weight != undefined
        );
        // console.log("hasWeightData", hasWeightData);

        // 収穫量データが返ってきている場合
        if (hasCropYeildData) {
          // データからX軸とY軸の情報を取得
          data.forEach((item) => {
            //アイテムの中身によって、座標に代入する値を制御
            this.dataHarvestBranching(item);

            // 「crop_yeild」のデータが存在する場合のみ、グラフの設定に座標をセット
            if(item.crop_yeild != null)
            {
              // その他データ描画時
              // X軸をセット
              this.chart.graphArrayData[this.graphIndex].options.xaxis.categories.push(
                this.x_value
              );
              // Y軸をセット
              this.chart.graphArrayData[this.graphIndex].series[0].data.push(
                // 小数第2位までデータをインプット
                parseFloat(this.y_value).toFixed(2)
              );
            }
          });
          // console.log("収穫量グラフを描画");
        }
        // 総収量データが返ってきている場合
        else if (hasWeightData) {
          // 各classごとのデータを格納するオブジェクトを初期化
          const groupedData = {};

          data.forEach((item) => {
            //アイテムの中身によって、座標に代入する値を制御
            this.dataBranching(item);

            // groupedDataを更新
            if (!groupedData[item.class]) {
              groupedData[item.class] = { name: item.class, data: [] };
            }

            // X軸とY軸に必要な値を追加
            if (this.x_value && this.y_value !== undefined) {
              // X軸のカテゴリーに日付を追加(重複を避ける)
              if (
                this.x_value &&
                this.chart.graphArrayData[this.graphIndex].options.xaxis.categories.indexOf(
                  this.x_value
                ) === -1
              ) {
                this.chart.graphArrayData[this.graphIndex].options.xaxis.categories.push(
                  this.x_value
                );
              }
              // データを追加
              groupedData[item.class].data.push({
                date: this.x_value,
                value: parseFloat(this.y_value),
              });
            }
          });

          // 各classごとのデータをchart.seriesに変換
          Object.keys(groupedData).forEach((item) => {
            const seriesData = this.chart.graphArrayData[
              this.graphIndex
            ].options.xaxis.categories.map((date) => {
              const dataPoint = groupedData[item].data.find((d) => d.date === date);

              //データがない場合はnullを返す
              return dataPoint ? dataPoint.value : null;
            });
            // console.log("seriesData", seriesData);
            this.chart.graphArrayData[this.graphIndex].series.push({
              name: groupedData[item].name,
              data: seriesData,
            });
          });

          // seriesが空でない場合のみ、そのデータを表示(有効なデータ点を持つ系列だけが表示され、〇表示もデータがある場合のみ表示される)
          // データポイントの中にnullが含まれる場合、その系列を削除
          this.chart.graphArrayData[this.graphIndex].series = this.chart.graphArrayData[
            this.graphIndex
          ].series.filter((series) => series.data.some((value) => value !== null));
          // console.log("総収量グラフを描画");
        }
        // 計測データ情報(growth_data)が返ってきている場合
        else if (hasMeasureData) 
        {
          // 「平均」のワードが入っている、かつSPAD値のデータが返ってきている場合には、各計測日の全苗番号の平均を算出
          if(this.judgePromptAverageWord(userPrompt) && hasMeasureSpadData)
          {
            // 各日付ごとのSPAD値の合計を算出
            const averageData = data.reduce((acc, curr) => {
              const date = curr.date;
              const quantity = parseFloat(curr.quantity_4);
      
              if (!acc[date]) {
                  acc[date] = { total: 0, count: 0 };
              }
      
              acc[date].total += quantity;
              acc[date].count += 1;
      
              return acc;
            }, {});
            // console.log("averageData", averageData);

            // 各日付ごとにSPAD値の平均を算出
            const averageArray = Object.keys(averageData).map(date => {
                const { total, count } = averageData[date];
                return { date, average: (total / count).toFixed(1) };
            });
            // console.log("averageArray", averageArray);

            // グラフデータの準備
            this.chart.graphArrayData[this.graphIndex].options.xaxis.categories = averageArray.map(item => item.date);
            this.chart.graphArrayData[this.graphIndex].series = [{
              name: '各日付ごとの全SPAD値の平均',
              data: averageArray.map(item => parseFloat(item.average)),
            }];

            // console.log("各日付のSPAD値の平均をグラフに出力");
          }
          // そうでない場合には、各苗番号ごとのデータをグラフに描画
          else
          {
            // 各bed_numberごとのデータを格納するオブジェクトを初期化
            const groupedData = {};
            let index = -1;

            data.forEach((item) => {
              // 配列の中身取得用のインデックスを用意
              index += 1;
              // console.log("index", index);

              //アイテムの中身によって、座標に代入する値を制御
              this.dataBranching(item);

              // インデックスに沿って、配列の要素を取得

              // "date_item_id_seed_id"の値を取得
              const dateItemIdSeed = data[index];
              const getDateItemIdSeed = dateItemIdSeed["date_item_id_seed_id"];
              // console.log("取り出した加工前の苗番号", getDateItemIdSeed);

              // 最後の'*'の位置を取得
              const lastAsteriskIndex = getDateItemIdSeed.lastIndexOf("*");
              // console.log("最後の*の位置", lastAsteriskIndex);

              // 日付部分を削除して、新しい値を作成

              // '*'以降の文字列を取得し、トリミング
              const newId = getDateItemIdSeed.substring(lastAsteriskIndex + 1).trim().replace(" ", "-");
              // const newId = getDateItemIdSeed.split("*")[1].trim().replace(" ", "-");
              // console.log("加工後の苗番号", newId);

              // groupedDataを更新
              if (!groupedData[newId]) {
                groupedData[newId] = { name: newId, data: [] };
              }

              // X軸とY軸に必要な値を追加
              if (this.x_value && this.y_value !== undefined) {
                // X軸のカテゴリーに日付を追加(重複を避ける)
                if (
                  this.x_value &&
                  this.chart.graphArrayData[this.graphIndex].options.xaxis.categories.indexOf(
                    this.x_value
                  ) === -1
                ) {
                  this.chart.graphArrayData[this.graphIndex].options.xaxis.categories.push(
                    this.x_value
                  );
                }
                // データを追加
                groupedData[newId].data.push({
                  date: this.x_value,
                  value: parseFloat(this.y_value),
                });
              }
            });

            // 各bed_numberごとのデータをchart.seriesに変換
            Object.keys(groupedData).forEach((bed_number) => {
              const seriesData = this.chart.graphArrayData[
                this.graphIndex
              ].options.xaxis.categories.map((date) => {
                const dataPoint = groupedData[bed_number].data.find((d) => d.date === date);

                //データがない場合はnullを返す
                return dataPoint ? dataPoint.value : null;
              });
              this.chart.graphArrayData[this.graphIndex].series.push({
                name: groupedData[bed_number].name,
                data: seriesData,
              });
            });

            // seriesが空でない場合のみ、そのデータを表示(有効なデータ点を持つ系列だけが表示され、〇表示もデータがある場合のみ表示される)
            // データポイントの中にnullが含まれる場合、その系列を削除
            this.chart.graphArrayData[this.graphIndex].series = this.chart.graphArrayData[
              this.graphIndex
            ].series.filter((series) => series.data.some((value) => value !== null));

            // console.log("各苗番号のデータをそのままグラフに出力");
          }
          // console.log("計測データのグラフを描画");
        }
        // 養液データが返ってきている場合
        else if (hasNutrientSolutionData) 
        {
          // 各bed_numberごとのデータを格納するオブジェクトを初期化
          const groupedData = {};

          data.forEach((item) => {
            //アイテムの中身によって、座標に代入する値を制御
            this.dataBranching(item);

            // groupedDataを更新
            if (!groupedData[item.bed_number]) {
              groupedData[item.bed_number] = { name: item.bed_number, data: [] };
            }

            // X軸とY軸に必要な値を追加
            if (this.x_value && this.y_value !== undefined) {
              // X軸のカテゴリーに日付を追加(重複を避ける)
              if (
                this.x_value &&
                this.chart.graphArrayData[this.graphIndex].options.xaxis.categories.indexOf(
                  this.x_value
                ) === -1
              ) {
                this.chart.graphArrayData[this.graphIndex].options.xaxis.categories.push(
                  this.x_value
                );
              }

              // データを追加
              groupedData[item.bed_number].data.push({
                date: this.x_value,
                value: parseFloat(this.y_value),
              });
            }
          });

          // 各bed_numberごとのデータをchart.seriesに変換
          Object.keys(groupedData).forEach((bed_number) => {
            const seriesData = this.chart.graphArrayData[
              this.graphIndex
            ].options.xaxis.categories.map((date) => {
              const dataPoint = groupedData[bed_number].data.find((d) => d.date === date);

              //データがない場合はnullを返す
              return dataPoint ? dataPoint.value : null;
            });
            this.chart.graphArrayData[this.graphIndex].series.push({
              name: groupedData[bed_number].name,
              data: seriesData,
            });
          });

          // seriesが空でない場合のみ、そのデータを表示(有効なデータ点を持つ系列だけが表示され、〇表示もデータがある場合のみ表示される)
          // データポイントの中にnullが含まれる場合、その系列を削除
          this.chart.graphArrayData[this.graphIndex].series = this.chart.graphArrayData[
           this.graphIndex
          ].series.filter((series) => series.data.some((value) => value !== null));

          // console.log("養液データのグラフを描画");
        }
        // 灌水量データが返ってきている場合(灌水量の変化量(after - before)に対する問い対応)
        else if(hasMeasureIrrigationBeforeAfterData)
        {
          // 各bed_numberごとのデータを格納するオブジェクトを初期化
          const groupedData = {};

          data.forEach((item) => {
            //アイテムの中身によって、座標に代入する値を制御
            this.dataBranching(item);

            // groupedDataを更新
            if (!groupedData[item.bed_number]) {
              groupedData[item.bed_number] = { name: item.bed_number, data: [] };
            }

            // X軸とY軸に必要な値を追加
            if (this.x_value && this.y_value !== undefined) {
              // X軸のカテゴリーに日付を追加(重複を避ける)
              if (
                this.x_value &&
                this.chart.graphArrayData[this.graphIndex].options.xaxis.categories.indexOf(
                  this.x_value
                ) === -1
              ) {
                this.chart.graphArrayData[this.graphIndex].options.xaxis.categories.push(
                  this.x_value
                );
              }

              // データを追加
              groupedData[item.bed_number].data.push({
                date: this.x_value,
                value: parseFloat(this.y_value),
              });
            }
          });

          // 各bed_numberごとのデータをchart.seriesに変換
          Object.keys(groupedData).forEach((bed_number) => {
            const seriesData = this.chart.graphArrayData[
              this.graphIndex
            ].options.xaxis.categories.map((date) => {
              const dataPoint = groupedData[bed_number].data.find((d) => d.date === date);

              //データがない場合はnullを返す
              return dataPoint ? dataPoint.value : null;
            });
            this.chart.graphArrayData[this.graphIndex].series.push({
              name: groupedData[bed_number].name,
              data: seriesData,
            });
          });

          // seriesが空でない場合のみ、そのデータを表示(有効なデータ点を持つ系列だけが表示され、〇表示もデータがある場合のみ表示される)
          // データポイントの中にnullが含まれる場合、その系列を削除
          this.chart.graphArrayData[this.graphIndex].series = this.chart.graphArrayData[
            this.graphIndex
          ].series.filter((series) => series.data.some((value) => value !== null));

          // console.log("灌水量データの水位変化(after - before)グラフを描画");
        }
        // 灌水量データが返ってきている場合(灌水量データの推移の問い合わせが来た場合)
        else if(hasMeasureIrrigationAfterData)
        {
          // 各bed_numberごとのデータを格納するオブジェクトを初期化
          const groupedData = {};

          data.forEach((item) => {
            //アイテムの中身によって、座標に代入する値を制御
            this.dataBranching(item);

            // groupedDataを更新
            if (!groupedData[item.bed_number]) {
              groupedData[item.bed_number] = { name: item.bed_number, data: [] };
            }

            // X軸とY軸に必要な値を追加
            if (this.x_value && this.y_value !== undefined) {
              // X軸のカテゴリーに日付を追加(重複を避ける)
              if (
                this.x_value &&
                this.chart.graphArrayData[this.graphIndex].options.xaxis.categories.indexOf(
                  this.x_value
                ) === -1
              ) {
                this.chart.graphArrayData[this.graphIndex].options.xaxis.categories.push(
                  this.x_value
                );
              }

              // データを追加
              groupedData[item.bed_number].data.push({
                date: this.x_value,
                value: parseFloat(this.y_value),
              });
            }
          });

          // 各bed_numberごとのデータをchart.seriesに変換
          Object.keys(groupedData).forEach((bed_number) => {
            const seriesData = this.chart.graphArrayData[
              this.graphIndex
            ].options.xaxis.categories.map((date) => {
              const dataPoint = groupedData[bed_number].data.find((d) => d.date === date);

              //データがない場合はnullを返す
              return dataPoint ? dataPoint.value : null;
            });
            this.chart.graphArrayData[this.graphIndex].series.push({
              name: groupedData[bed_number].name,
              data: seriesData,
            });
          });

          // seriesが空でない場合のみ、そのデータを表示(有効なデータ点を持つ系列だけが表示され、〇表示もデータがある場合のみ表示される)
          // データポイントの中にnullが含まれる場合、その系列を削除
          this.chart.graphArrayData[this.graphIndex].series = this.chart.graphArrayData[
            this.graphIndex
          ].series.filter((series) => series.data.some((value) => value !== null));

          // console.log("灌水量データ(変更後)のみ出力、グラフを描画");
        }
        // 糖度データが返ってきている場合
        else if(hasBrixData)
        {
          // ユーザープロンプトの中に、「平均」が含まれている場合
          if(this.judgePromptAverageWord(userPrompt))
          {
            // 「平均」の質問 + 「苗番号」の指定が入っていた場合
            // 各苗番号ごとに各月で糖度の平均をだす 
            if(this.judgePromptWord(userPrompt))
            {
              // 各月の糖度平均を算出(苗番号ごとに各月の平均を算出)
              const monthlyData = {};

              // 各月の苗番号ごとの合計を算出
              data.forEach(item => {
                if(item.bed_number != "平均値")
                {
                  const month = item.date.substring(0, 7); // YYYY-MM
                  const bedNumber = item.bed_number;
                  const brix = parseFloat(item.brix);

                  if (!monthlyData[month]) {
                      monthlyData[month] = {};
                  }

                  if (!monthlyData[month][bedNumber]) {
                      monthlyData[month][bedNumber] = { total: 0, count: 0 };
                  }

                  monthlyData[month][bedNumber].total += brix;
                  monthlyData[month][bedNumber].count += 1; 
                }
              });
              // console.log("各月の苗番号ごとの合計", monthlyData);

              const result = {};

              // 各月の苗番号ごとの平均を算出
              Object.keys(monthlyData).forEach(month => {
                  result[month] = {};
                  Object.keys(monthlyData[month]).forEach(bedNumber => {
                      const averageBrix = (monthlyData[month][bedNumber].total / monthlyData[month][bedNumber].count).toFixed(1);
                      result[month][bedNumber] = averageBrix;
                  });
              });
              // console.log("各月の苗番号ごとの平均", result);

              const series = {};

              // Seriesを作成
              Object.keys(result).forEach(month => {
                Object.keys(result[month]).forEach(bedNumber => {
                  if (!series[bedNumber]) {
                    series[bedNumber] = {
                      name: bedNumber,
                      data: Array(Object.keys(result).length).fill(null),
                    };
                  }
                  const monthIndex = Object.keys(result).indexOf(month);
                  series[bedNumber].data[monthIndex] = parseFloat(result[month][bedNumber]);
                });
              });

              // X-axis categories
              const categories = Object.keys(result);
              // console.log("X軸にセットするデータ", categories);

              // Prepare series for ApexCharts
              const seriesArray = Object.values(series);
              // console.log("Y軸にセットするデータ", seriesArray);

              // X軸に日付(年/月)データを代入
              this.chart.graphArrayData[this.graphIndex].options.xaxis.categories = categories;
              // Y軸に各月の苗番号毎の平均糖度データを代入
              this.chart.graphArrayData[this.graphIndex].series = seriesArray;

              // console.log("各苗番号ごとの平均糖度を各月で出力");
            }
            // 「苗番号」の指定がない場合は、そのまま各月の平均糖度を算出
            else
            {
              // 各月の糖度平均を算出(苗番号ごとではない)
              const monthlyData = {};
      
              // 月毎の合計を算出
              data.forEach(item => {
                if(item.bed_number != "平均値")
                {
                  // YYYY-MM形式
                  const month = item.date.substring(0, 7);
                  const brix = parseFloat(item.brix);
                
                  if (!monthlyData[month]) {
                      monthlyData[month] = { total: 0, count: 0 };
                  }
                    
                  monthlyData[month].total += brix;
                  monthlyData[month].count += 1; 
                }
              });
              // console.log("月毎の合計", monthlyData);
              
              // 月毎の平均を算出
              const result = Object.keys(monthlyData).map(month => {
                return {
                    month: month,
                    averageBrix: (monthlyData[month].total / monthlyData[month].count).toFixed(1)
                };
              });
              // console.log("月毎の平均", result);

              // X軸に日付(年/月)データを代入
              this.chart.graphArrayData[this.graphIndex].options.xaxis.categories = result.map(item => item.month);
              // Y軸に月毎の平均糖度データを代入
              this.chart.graphArrayData[this.graphIndex].series = [{
                name: '各月の全糖度値の平均',
                data: result.map(item => item.averageBrix),
              }];

              // console.log("平均糖度(苗番号関係なし)を各月で出力");
            }
          }
          // そうでない場合は、そのまま折れ線として糖度データを日付毎に表示させる
          else
          {
            // 各bed_numberごとのデータを格納するオブジェクトを初期化
            const groupedData = {};

            data.forEach((item) => {
              //アイテムの中身によって、座標に代入する値を制御
              this.dataBranching(item);

              // groupedDataを更新
              if (!groupedData[item.bed_number]) {
                groupedData[item.bed_number] = { name: item.bed_number, data: [] };
              }

              // X軸とY軸に必要な値を追加
              if (this.x_value && this.y_value !== undefined) {
                // X軸のカテゴリーに日付を追加(重複を避ける)
                if (
                  this.x_value &&
                  this.chart.graphArrayData[this.graphIndex].options.xaxis.categories.indexOf(
                    this.x_value
                  ) === -1
                ) {
                  this.chart.graphArrayData[this.graphIndex].options.xaxis.categories.push(
                    this.x_value
                  );
                }

                // データを追加
                groupedData[item.bed_number].data.push({
                  date: this.x_value,
                  value: parseFloat(this.y_value),
                });
              }
            });

            // 各bed_numberごとのデータをchart.seriesに変換
            Object.keys(groupedData).forEach((bed_number) => {
              const seriesData = this.chart.graphArrayData[
                this.graphIndex
              ].options.xaxis.categories.map((date) => {
                const dataPoint = groupedData[bed_number].data.find((d) => d.date === date);

                //データがない場合はnullを返す
                return dataPoint ? dataPoint.value : null;
              });
              this.chart.graphArrayData[this.graphIndex].series.push({
                name: groupedData[bed_number].name,
                data: seriesData,
              });
            });

            // seriesが空でない場合のみ、そのデータを表示(有効なデータ点を持つ系列だけが表示され、〇表示もデータがある場合のみ表示される)
            // データポイントの中にnullが含まれる場合、その系列を削除
            this.chart.graphArrayData[this.graphIndex].series = this.chart.graphArrayData[
              this.graphIndex
            ].series.filter((series) => series.data.some((value) => value !== null));
            // console.log("各日付ごとの糖度データを折れ線グラフとして表示");
          }
        }
        // ハウス内気温、グリーンハウスモニター情報が返ってきている場合
        else {
          // 各bed_numberごとのデータを格納するオブジェクトを初期化
          const groupedData = {};

          data.forEach((item) => {
            //アイテムの中身によって、座標に代入する値を制御
            this.dataBranching(item);

            // groupedDataを更新
            if (!groupedData[item.bed_number]) {
              groupedData[item.bed_number] = { name: item.bed_number, data: [] };
            }

            // X軸とY軸に必要な値を追加
            if (this.x_value && this.y_value !== undefined) {
              // X軸のカテゴリーに日付を追加(重複を避ける)
              if (
                this.x_value &&
                this.chart.graphArrayData[this.graphIndex].options.xaxis.categories.indexOf(
                  this.x_value
                ) === -1
              ) {
                this.chart.graphArrayData[this.graphIndex].options.xaxis.categories.push(
                  this.x_value
                );
              }

              // データを追加
              groupedData[item.bed_number].data.push({
                date: this.x_value,
                value: parseFloat(this.y_value),
              });
            }
          });

          // 各bed_numberごとのデータをchart.seriesに変換
          Object.keys(groupedData).forEach((bed_number) => {
            const seriesData = this.chart.graphArrayData[
              this.graphIndex
            ].options.xaxis.categories.map((date) => {
              const dataPoint = groupedData[bed_number].data.find((d) => d.date === date);

              //データがない場合はnullを返す
              return dataPoint ? dataPoint.value : null;
            });
            this.chart.graphArrayData[this.graphIndex].series.push({
              name: groupedData[bed_number].name,
              data: seriesData,
            });
          });

          // seriesが空でない場合のみ、そのデータを表示(有効なデータ点を持つ系列だけが表示され、〇表示もデータがある場合のみ表示される)
          // データポイントの中にnullが含まれる場合、その系列を削除
          this.chart.graphArrayData[this.graphIndex].series = this.chart.graphArrayData[
            this.graphIndex
          ].series.filter((series) => series.data.some((value) => value !== null));
          // console.log("ハウス内気温、グリーンハウスモニター情報のグラフを描画");
        }

        // console.log("折れ線グラフを出力");
      }
      // バックエンドから返ってきたグラフ種別が「縦棒グラフ」の場合
      else if(graphType == "bar")
      {
        // 糖度データ問い合わせ確認(bed番号が返ってきている場合でも一緒に判断)
        // dataがnullではなく、配列である、かつ「date」「brix」「bed_number」が両方ともundefinedではない場合にtrueを返して縦棒グラフを生成する
        const hasBrixData = Array.isArray(data) && data.some(item => 
          item.date !== undefined && item.brix !== undefined && item.bed_number !== undefined
        );
        // console.log("hasBrixData", hasBrixData);

        // 収穫量データ問い合わせ確認
        // dataがnullではなく、配列である、かつ「date」「crop_yeild」が両方ともundefinedではない場合にtrueを返して縦棒グラフを生成する
        const hasHarvestData = Array.isArray(data) && data.some(item => 
          item.date !== undefined && item.crop_yeild !== undefined
        );
        // console.log("hasHarvestData", hasHarvestData);

        // グリーンハウスモニター情報問い合わせ確認
        // dataがnullではなく、配列である、かつ「date」「date_time」「outeside_temprerature」などのプロパティが両方ともundefinedではない場合にtrueを返して縦棒グラフを生成する
        const hasGreenHouseData = Array.isArray(data) && data.some(item => 
          item.date !== undefined && 
          (item.outside_temperature !== undefined || item.inside_temperature !== undefined || item.target_temperature !== undefined
            || item.inside_relative_humidity !== undefined || item.outside_relative_humidity !== undefined || item.solar_radiation_intensity !== undefined
            || item.co2_concentration !== undefined || item.photosynthesis_rate !== undefined
          )
        );
        // console.log("hasGreenHouseData", hasGreenHouseData);

        // 総収量データ情報問い合わせ確認
        // dataがnullではなく、配列である、かつ「date」「weight」のプロパティが両方ともundefinedではない場合にtrueを返して縦棒グラフを生成する
        const hasGrossHarvestData = Array.isArray(data) && data.some(item => 
          item.date !== undefined && item.weight !== undefined
        );
        // console.log("hasGrossHarvestData", hasGrossHarvestData);

        // 糖度データ一式が返ってきている場合
        // データの平均値を出して、縦棒グラフにする
        if(hasBrixData)
        {
          // console.log("糖度データの準備開始");

          // ユーザープロンプトの中に、"ベッド", "ベッド番号", "bed", "bed番号", "Bed", "Bed番号", "苗番号", "調査株", "#"の
          // いずれかの単語が入っている場合：各苗番号ごとの平均値を年で計算、グラフに出力
          // そうでない場合：各年の全計測値を平均、グラフに出力
          if(this.judgePromptWord(userPrompt))
          {
            // ユーザープロンプト中に「今作」のキーワードが入っている場合には今作のデータを算出
            if(this.judgePromptNowMadeWord(userPrompt))
            {
              // グループ化するための変数を用意
              const groupedData = {};

              // 今作(2024/8/22～2025/1/15)データの処理
              const newDataPeriodStart = new Date(this.period_of_seedlings_made_start);
              const newDataPeriodEnd = new Date(this.period_of_seedlings_made_final);
              // console.log("指定した今作の期間", newDataPeriodStart + "～" + newDataPeriodEnd);

              // 今作の期間に含まれる年をセットに追加
              const excludedYears = new Set();
              // 2024年
              excludedYears.add(newDataPeriodStart.getFullYear().toString());
              // 2025年
              excludedYears.add(newDataPeriodEnd.getFullYear().toString());
              // console.log("除外する期間の年", excludedYears);

              // 除外する年以外のデータの合計を苗番号ごとに算出
              data.forEach(item => {
                // 年を取得
                const year = new Date(item.date).getFullYear();
                // console.log("year", year);

                // 苗番号を取得
                const bedNumber = item.bed_number;
                // console.log("bedNumber", bedNumber);
                
                // 糖度を取得
                const brixValue = parseFloat(item.brix);
                // console.log("brixValue", brixValue);

                // "平均値"をスキップ
                // ※平均値の値は一部の日付で格納されているが、こちら側で全集計を行うためこの値は取得しない
                if (bedNumber === "平均値") {
                  // 何もせずに次のループへ
                  return;
                }

                // 年が除外年のセットに含まれている場合はスキップ
                if (excludedYears.has(year.toString())) {
                  // console.log(`${year.toString()}年のデータは計算しません`);

                  // 除外対象の年のデータは計算しない
                  return;
                }

                // 年とbed_numberの組み合わせでグループ化
                if (!groupedData[year]) {
                  groupedData[year] = {};
                }
                if (!groupedData[year][bedNumber]) {
                  groupedData[year][bedNumber] = { total: 0, count: 0 };
                }

                groupedData[year][bedNumber].total += brixValue;
                groupedData[year][bedNumber].count += 1;
              });
              // console.log("groupedData", groupedData);

              // グラフ出力用の変数を用意
              const chartData = { years: [], series: [] };

              // 既存のデータを追加
              Object.keys(groupedData).forEach(year => {
                const bedNumbers = Object.keys(groupedData[year]);

                bedNumbers.forEach(bedNumber => {
                  const avgBrix = groupedData[year][bedNumber].total / groupedData[year][bedNumber].count;

                  const seriesIndex = chartData.series.findIndex(series => series.name === bedNumber);
                  if (seriesIndex === -1) {
                    chartData.series.push({ name: bedNumber, data: new Array(Object.keys(groupedData).length).fill(0) });
                  }
                  
                  const bedSeries = chartData.series.find(series => series.name === bedNumber);
                  bedSeries.data[chartData.years.length] = parseFloat(avgBrix.toFixed(1));
                });

                // 年データを追加
                chartData.years.push(year);
              });
              // console.log("除外された年以外の平均", groupedData);
              // console.log("除外する年以外の横軸ラベル", chartData.years);

              // 今作のデータのフィルタリング
              const nowData = data.filter(item => {
                  const date = new Date(item.date);
                  return (date >= newDataPeriodStart && date <= newDataPeriodEnd);
              });
              // console.log("nowData", nowData);

              // 今作のデータの平均計算
              if (nowData.length > 0) {
                const nowBrixCollection = {};

                // 今作のデータを集計する処理
                nowData.forEach(item => {
                    const bedNumber = item.bed_number;
                    const brixValue = parseFloat(item.brix);

                    if (!nowBrixCollection[bedNumber]) {
                        nowBrixCollection[bedNumber] = { total: 0, count: 0 };
                    }

                    // 現在の苗番号のデータを集計
                    nowBrixCollection[bedNumber].total += brixValue;
                    nowBrixCollection[bedNumber].count += 1;
                });

                // 今作のデータをチャートに追加
                Object.keys(nowBrixCollection).forEach(bedNumber => {
                    const avgBrix = nowBrixCollection[bedNumber].total / nowBrixCollection[bedNumber].count;

                    const seriesIndex = chartData.series.findIndex(series => series.name === bedNumber);
                    if (seriesIndex === -1) {
                        // 苗番号シリーズを追加
                        chartData.series.push({ name: bedNumber, data: new Array(chartData.years.length).fill(0) });
                    }

                    const nowSeries = chartData.series.find(series => series.name === bedNumber);
                    // 今作のデータを追加
                    nowSeries.data.push(parseFloat(avgBrix.toFixed(1)));
                });

                // 今作のラベルを追加
                chartData.years.push('今作');
              }
              // console.log("今作データ平均計算後の横軸データ", chartData.years);
              // console.log("今作データ平均計算後の縦軸データ", chartData.series);

              // X軸に年データを代入
              this.chart.graphArrayData[this.graphIndex].options.xaxis.categories = chartData.years;
              // Y軸に平均値のデータを代入
              this.chart.graphArrayData[this.graphIndex].series = chartData.series;

              // console.log("今作のデータを苗番号毎にグラフに反映");
            }
            // 「今作」のワードが入っていない場合には、そのまま指定された期間でグラフを描画
            else
            {
              // グループ化するための変数を用意
              const groupedData = {};

              data.forEach(item => {
                // 年を取得
                const year = new Date(item.date).getFullYear();
                // console.log("year", year);

                // 苗番号を取得
                const bedNumber = item.bed_number;
                // console.log("bedNumber", bedNumber);
                
                // 糖度を取得
                const brixValue = parseFloat(item.brix);
                // console.log("brixValue", brixValue);

                // "平均値"をスキップ
                // ※平均値の値は一部の日付で格納されているが、こちら側で全集計を行うためこの値は取得しない
                if (bedNumber === "平均値") {
                  // 何もせずに次のループへ
                  return;
                }

                // 年とbed_numberの組み合わせでグループ化
                if (!groupedData[year]) {
                  groupedData[year] = {};
                }
                if (!groupedData[year][bedNumber]) {
                  groupedData[year][bedNumber] = { total: 0, count: 0 };
                }

                groupedData[year][bedNumber].total += brixValue;
                groupedData[year][bedNumber].count += 1;
              });
              // console.log("groupedData", groupedData);

              // グラフ出力用の変数を用意
              const chartData = { years: [], series: [] };

              // 平均値を計算し、チャート用のデータ形式に整形
              Object.keys(groupedData).forEach(year => {
                // chartData.years.push(year);
                const yearData = [];
                const bedNumbers = Object.keys(groupedData[year]);

                // 各bed番号ごとに計算
                bedNumbers.forEach(bedNumber => {
                  // 各bed番号ごとの平均値を計算
                  const avgBrix = groupedData[year][bedNumber].total / groupedData[year][bedNumber].count;
                  // 計算された平均値を、小数点第1位までに変換
                  yearData.push(parseFloat(avgBrix.toFixed(1)));
                  
                  // 年リストに存在しない場合は初期化
                  const seriesIndex = chartData.series.findIndex(series => series.name === bedNumber);
                  if (seriesIndex === -1) {
                    chartData.series.push({ name: bedNumber, data: new Array(Object.keys(groupedData).length).fill(0) });
                  }
                  // データを年に合わせて更新
                  const bedSeries = chartData.series.find(series => series.name === bedNumber);
                  // 平均値を小数第1位までに変換して、現年に挿入
                  bedSeries.data[chartData.years.length] = parseFloat(avgBrix.toFixed(1));
                });

                chartData.years.push(year);
              });
              // console.log("chartData", chartData);

              // X軸に年データを代入
              this.chart.graphArrayData[this.graphIndex].options.xaxis.categories = chartData.years;
              // Y軸に平均値のデータを代入
              this.chart.graphArrayData[this.graphIndex].series = chartData.series;

              // console.log("そのまま各Bed番号ごとの平均値をグラフとして出力");
            }
          }
          // ユーザープロンプトの中に指定したキーワードが入っていない場合において、「今作」のキーワードが入っていた場合には今作データも算出してグラフに反映
          // そうでない場合には、そのまま各年ごとの糖度データの平均を算出・縦棒グラフに反映
          // ※「date」「bed_number」「brix」が1セットで必ず返ってきている想定
          else
          {
            // ユーザープロンプト中に「今作」のワードが入っている場合には、今作の平均データを算出、グラフに反映
            if(this.judgePromptNowMadeWord(userPrompt))
            {
              // 今作(2024/8/22～2025/1/15)データの処理
              const newDataPeriodStart = new Date(this.period_of_seedlings_made_start);
              const newDataPeriodEnd = new Date(this.period_of_seedlings_made_final);
              // console.log("指定した今作の期間", newDataPeriodStart + "～" + newDataPeriodEnd);

              // 今作の期間に含まれる年をセットに追加
              const excludedYears = new Set();
              // 2024年
              excludedYears.add(newDataPeriodStart.getFullYear().toString());
              // 2025年
              excludedYears.add(newDataPeriodEnd.getFullYear().toString());
              // console.log("除外する期間の年", excludedYears);

              // 年ごとのbrixデータを集計
              const yearlyBrix = data.reduce((acc, entry) => {
                // "bad_number"が"平均値"でない場合のみ処理を続行
                if(entry.bed_number != "平均値") {
                  const year = entry.date.split('-')[0];
                  const brixValue = parseFloat(entry.brix);
                  // const entryDate = new Date(entry.date);
                  
                  // 年が除外年のセットに含まれている場合はスキップ
                  if (excludedYears.has(year)) {
                    // console.log(`${year}年のデータは計算しません`);

                    // 除外対象の年のデータは計算しない
                    return acc;
                  }
                  
                  // 年の累計
                  if (!acc[year]) 
                  {
                    acc[year] = { total: 0, count: 0 };
                  }
                  acc[year].total += brixValue;
                  acc[year].count += 1; 
                }
                return acc;
              }, {});

              // 平均を計算
              const averages = Object.keys(yearlyBrix).map(year => ({
                year,
                average: (yearlyBrix[year].total / yearlyBrix[year].count).toFixed(1)
              }));

              // 「2024/8/22～2025/1/15」データの処理
              const newData = data.filter(entry => {
                const date = new Date(entry.date);
                return date >= newDataPeriodStart && date <= newDataPeriodEnd;
              });

              // 新作データの平均を計算
              let newTotal = 0;
              let newCount = 0;

              newData.forEach(entry => {
                if(entry.bed_number != "平均値") {
                  newTotal += parseFloat(entry.brix);
                  newCount++;
                }
              });

              // 新作の平均値を計算（存在する場合のみ）
              const newAverage = (newCount > 0) ? (newTotal / newCount).toFixed(1) : 0;

              // X軸データを年データと新作を追加
              const xCategories = [...averages.map(data => data.year), "今作"];
              this.chart.graphArrayData[this.graphIndex].options.xaxis.categories = xCategories;

              // Y軸に計算後の各年の計測値の平均データを代入
              const yData = [...averages.map(data => data.average), newAverage];
              this.chart.graphArrayData[this.graphIndex].series = [{
                name: '各年の全糖度値の平均',
                data: yData,
              }]; 

              // console.log("今作のデータを算出、グラフに出力");
            }
            // ユーザープロンプト中に「今作」のワードが入っていない場合には、そのままグラフを表示
            else
            {
              // 年ごとのbrixデータを集計
              const yearlyBrix = data.reduce((acc, entry) => {
                // "bad_number"が"平均値"でない場合のみ処理を続行
                // ※"平均値"の値は、計算に含めない
                if(entry.bed_number != "平均値")
                {
                  const year = entry.date.split('-')[0];
                  const brixValue = parseFloat(entry.brix);
                  if (!acc[year]) {
                    acc[year] = { total: 0, count: 0 };
                  }
                  acc[year].total += brixValue;
                  acc[year].count += 1; 
                }
                return acc;
              }, {});
              // console.log("年毎のbrixデータの合計", yearlyBrix);

              // 平均を計算
              const averages = Object.keys(yearlyBrix).map(year => ({
                year,
                average: (yearlyBrix[year].total / yearlyBrix[year].count).toFixed(1)
              }));
              // console.log("brixデータの各年の平均", averages);

              // X軸に年データを代入
              this.chart.graphArrayData[this.graphIndex].options.xaxis.categories = averages.map(data => data.year);

              // Y軸に計算後の各年の計測値の平均データを代入
              this.chart.graphArrayData[this.graphIndex].series = [{
                name: '各年の全糖度値の平均',
                data: averages.map(data => data.average),
              }];

              // console.log("各年の全平均値をグラフとして出力");
            }
          }

          // console.log("糖度の過去データの平均値を縦棒グラフとして出力");
        }
        // 収穫量データが返って来ている場合
        // それぞれの収穫量を年毎にグラフに表示
        else if(hasHarvestData)
        {
          const chartData = { dates: [], series: [{ name: "収穫量", data: [] }] };

          data.forEach(item => {
            // 収穫量データが存在するデータのみ、配列に格納
            if(item.crop_yeild != null)
            {
              // 日付を取得
              const date = item.date;
              // X軸に格納する日付を格納
              chartData.dates.push(date);

              // 収穫量を取得
              const crop_yeild = parseFloat(item.crop_yeild).toFixed(2);
              // Y軸に格納する収穫量データを格納
              chartData.series[0].data.push(crop_yeild);
            }
          });

          // X軸に日付データを代入
          this.chart.graphArrayData[this.graphIndex].options.xaxis.categories = chartData.dates;
          // Y軸に収穫量のデータを代入
          this.chart.graphArrayData[this.graphIndex].series = chartData.series;

          // console.log("各年の収穫量データを縦棒グラフとして出力");
        }
        // グリーンハウスモニター情報が返ってきている場合
        // 返ってきた日付をカウントして、各年数ごとにグラフを表示する
        else if(hasGreenHouseData)
        {
          const uniqueDates = {};

          // ユニークな日付をカウントする
          data.forEach(item => {
            const date = new Date(item.date);
            const year = date.getFullYear();
            // YYYY-MM-DD
            const day = date.toISOString().split('T')[0];

            if (!uniqueDates[year]) {
              uniqueDates[year] = new Set();
              // console.log("年が変わりました");
            }
            uniqueDates[year].add(day);
          });

          // 年ごとの日数を計算
          const chartData = Object.keys(uniqueDates).map(year => ({
            year: year,
            daysCount: uniqueDates[year].size
          }));

          // X軸に日付データを代入
          this.chart.graphArrayData[this.graphIndex].options.xaxis.categories = chartData.map(data => data.year);

          // Y軸に収穫量のデータを代入
          this.chart.graphArrayData[this.graphIndex].series = [{
            name: '日数',
            data: chartData.map(data => data.daysCount),
          }];

          // console.log("各年の日付の日数を縦棒グラフとして出力");
        }
        // 総収量データ情報が返ってきている場合
        // 各年ごとの総収量を計算、グラフに出力
        else if(hasGrossHarvestData)
        {
          // 年ごとのweightの合計を算出
          const result = data.reduce((acc, item) => {
              const year = new Date(item.date).getFullYear();
              const weight = parseFloat(item.weight);
              
              if (!acc[year]) {
                  acc[year] = 0;
              }
              
              acc[year] += weight;
              return acc;
          }, {});

          // 小数第2位までに制限
          const formattedResult = Object.keys(result).reduce((acc, year) => {
              // toFixedで小数第2位までを取得し、parseFloatで数値に戻す
              acc[year] = parseFloat(result[year].toFixed(2));
              return acc;
          }, {});

          // オブジェクトを配列形式に整形
          const chartData = Object.keys(result).map(year => ({
              year: year,
              totalWeight: formattedResult[year]
          }));

          // X軸に日付データを代入
          this.chart.graphArrayData[this.graphIndex].options.xaxis.categories = chartData.map(item => item.year);
          // Y軸に収穫量のデータを代入
          this.chart.graphArrayData[this.graphIndex].series = [{
            name: '総収量合計',
            data: chartData.map(item => item.totalWeight),
          }];

          // console.log("各年の総収量データの合計を縦棒グラフとして出力");
        }

        // console.log("縦棒グラフを出力");
      }
      // バックエンドから返ってきたグラフ種別が「円グラフ」の場合
      else
      {
        // 総収量データ情報問い合わせ確認
        // dataがnullではなく、配列である、かつ「date」「class」「weight」のプロパティが両方ともundefinedではない場合にtrueを返して円グラフを生成する
        const hasGrossHarvestData = Array.isArray(data) && data.some(item => 
          item.date !== undefined && item.class !== undefined && item.weight !== undefined
        );
        // console.log("hasGrossHarvestData", hasGrossHarvestData);

        // 総収量データが返ってきている場合
        if(hasGrossHarvestData)
        {
          // 各 class ごとの weight の合計を計算
          const classWeights = {};
          let totalWeight = 0;

          data.forEach(item => {
            if (item.class === "2S_3L以上合計") {
              // 「2S_3L以上合計」の場合、weightが空の場合、次のデータを取得
              if (item.weight === "") 
              {
                return;
              }
            }
            
            const weight = parseFloat(item.weight);
            
            if (!isNaN(weight)) 
            {
              // 各クラス毎の合計を算出
              classWeights[item.class] = (classWeights[item.class] || 0) + weight;
              totalWeight += weight;
            }
          });
          // console.log("classWeights", classWeights);
          // console.log("totalWeight", totalWeight);

          // 総合計を計算
          if (totalWeight !== 0) {
            // クラス名を代入
            this.chart.graphArrayData[this.graphIndex].options.labels = Object.keys(classWeights);
            // console.log("円グラフに表示させるラベル", Object.keys(classWeights));

            // 各クラスの割合を計算し格納
            this.chart.graphArrayData[this.graphIndex].series = Object.values(classWeights).map(weight => {
              const percentage = (weight / totalWeight) * 100;
              // 小数第1位を四捨五入して整数に変換
              return Math.round(percentage);
            });
            // console.log("円グラフとしてだす割合データ", this.chart.graphArrayData[this.graphIndex].series);
          }

          // console.log("トマトサイズの割合を円グラフとして出力");
        }

        // console.log("円グラフを出力");
      }
    },
    // ユーザープロンプトの中身チェック(折れ線グラフ・縦棒グラフ、糖度データ用)
    // プロンプトの中に該当するワードが入っている場合にはtrueを返す
    judgePromptWord(userPrompt)
    {
      // console.log("userPrompt", userPrompt);

      const keywords = ["ベッド", "ベッド番号", "bed", "bed番号", "Bed", "Bed番号", "苗番号", "調査株", "#"];
      const containsKeywords = keywords.some(keyword => userPrompt.includes(keyword));
      // console.log("糖度データユーザープロンプトをチェックします");
      // console.log("結果", containsKeywords);

      return containsKeywords
    },
    // ユーザープロンプトの中身チェック(折れ線グラフ、糖度データ用)
    // プロンプトの中に該当するワードが入っている場合にはtrueを返す
    judgePromptAverageWord(userPrompt)
    {
      // console.log("userPrompt", userPrompt);

      const keywords = ["平均"];
      const containsKeywords = keywords.some(keyword => userPrompt.includes(keyword));
      // console.log("ユーザープロンプトをチェックします");
      // console.log("結果", containsKeywords);

      return containsKeywords
    },
    // ユーザープロンプトの中身チェック(縦棒グラフ、糖度データ用)
    judgePromptNowMadeWord(userPrompt)
    {
      // console.log("userPrompt", userPrompt);

      const keywords = ["今作"];
      const containsKeywords = keywords.some(keyword => userPrompt.includes(keyword));
      // console.log("ユーザープロンプトをチェックします");
      // console.log("結果", containsKeywords);

      return containsKeywords
    },
    // 折れ線グラフの種別判断で、かつ収穫量データが返ってきたときのデータ分岐判断
    dataHarvestBranching(item)
    {
      // 収穫量データ(crop_harvest)が返ってきている場合は、収穫量データを各座標に代入
      if (item.crop_yeild != null) 
      {
        // X軸に日付情報を代入
        this.x_value = item.date;
        // Y軸に収穫量情報を代入
        this.y_value = item.crop_yeild;
        // console.log("収穫量のグラフを生成します");
      }
      else
      {
        // console.log("データがないため、次のデータを取得します");
        return;
      }
    },
    // 座標に代入するデータの分岐判断
    dataBranching(item) {
      // 糖度データ(brix_state)が返ってきている場合は、糖度データを各座標に代入
      if (item.brix != null) {
        // X軸に日付情報を代入
        this.x_value = item.date;
        // Y軸に糖度情報を代入
        this.y_value = item.brix;

        // console.log("糖度のグラフを生成します");
      }
      // ハウス内気温情報(greenhouse_daily_temperature)が返ってきている場合は、それぞれ返ってきたパラメーターの情報を代入
      // 1.「平均気温」の情報が返ってきている場合(仮、クエリは必要な情報以外取ってこない想定)
      else if (item.average_temperature != null) {
        // X軸に日付情報を代入
        this.x_value = item.date;
        // Y軸に「平均気温」情報を代入
        this.y_value = item.average_temperature;

        // console.log("平均気温のグラフを生成します");
      }
      // 2.「昼平均気温」の情報が返ってきている場合(仮、クエリは必要な情報以外取ってこない想定)
      else if (item.average_daytime_temperature != null) {
        // X軸に日付情報を代入
        this.x_value = item.date;
        // Y軸に「昼平均気温」情報を代入
        this.y_value = item.average_daytime_temperature;

        // console.log("昼平均気温のグラフを生成します");
      }
      // 3.「夜平均気温」の情報が返ってきている場合(仮、クエリは必要な情報以外取ってこない想定)
      else if (item.average_nighttime_temperature != null) {
        // X軸に日付情報を代入
        this.x_value = item.date;
        // Y軸に「夜平均気温」情報を代入
        this.y_value = item.average_nighttime_temperature;

        // console.log("夜平均気温のグラフを生成します");
      }
      // 4.「最高気温」の情報が返ってきている場合(仮、クエリは必要な情報以外取ってこない想定)
      else if (item.max_temperature != null) {
        // X軸に日付情報を代入
        this.x_value = item.date;
        // Y軸に「最高気温」情報を代入
        this.y_value = item.max_temperature;

        // console.log("最高気温のグラフを生成します");
      }
      // 5.「最低気温」の情報が返ってきている場合(仮、クエリは必要な情報以外取ってこない想定)
      else if (item.min_temperature != null) {
        // X軸に日付情報を代入
        this.x_value = item.date;
        // Y軸に「最低気温」情報を代入
        this.y_value = item.min_temperature;

        // console.log("最低気温のグラフを生成します");
      }
      // グリーンハウスモニター情報(greenhouse_info)が返ってきている場合は、それぞれ返ってきたパラメーターの情報を代入
      // 1.「ハウス内温度」の情報が返ってきている場合(仮、クエリは必要な情報以外取ってこない想定)
      else if (item.inside_temperature != null) {
        // X軸に日時情報を代入
        this.x_value = item.date_time;
        // Y軸に「ハウス内温度」情報を代入
        this.y_value = item.inside_temperature;

        // console.log("ハウス内温度のグラフを生成します");
      }
      // 2.「室外温度」の情報が返ってきている場合(仮、クエリは必要な情報以外取ってこない想定)
      else if (item.outside_temperature != null) {
        // X軸に日時情報を代入
        this.x_value = item.date_time;
        // Y軸に「室外温度」情報を代入
        this.y_value = item.outside_temperature;

        // console.log("室外温度のグラフを生成します");
      }
      // 3.「目標温度」の情報が返ってきている場合(仮、クエリは必要な情報以外取ってこない想定)
      else if (item.target_temperature != null) {
        // X軸に日時情報を代入
        this.x_value = item.date_time;
        // Y軸に「目標温度」情報を代入
        this.y_value = item.target_temperature;

        // console.log("目標温度のグラフを生成します");
      }
      // 4.「ハウス内相対湿度」の情報が返ってきている場合(仮、クエリは必要な情報以外取ってこない想定)
      else if (item.inside_relative_humidity != null) {
        // X軸に日時情報を代入
        this.x_value = item.date_time;
        // Y軸に「ハウス内相対湿度」情報を代入
        this.y_value = item.inside_relative_humidity;

        // console.log("ハウス内相対湿度のグラフを生成します");
      }
      // 5.「室外相対湿度」の情報が返ってきている場合(仮、クエリは必要な情報以外取ってこない想定)
      else if (item.outside_relative_humidity != null) {
        // X軸に日時情報を代入
        this.x_value = item.date_time;
        // Y軸に「室外相対湿度」情報を代入
        this.y_value = item.outside_relative_humidity;

        // console.log("室外相対湿度のグラフを生成します");
      }
      // 6.「屋外日射」の情報が返ってきている場合(仮、クエリは必要な情報以外取ってこない想定)
      else if (item.solar_radiation_intensity != null) {
        // X軸に日時情報を代入
        this.x_value = item.date_time;
        // Y軸に「屋外日射」情報を代入
        this.y_value = item.solar_radiation_intensity;

        // console.log("屋外日射のグラフを生成します");
      }
      // 7.「CO2濃度」の情報が返ってきている場合(仮、クエリは必要な情報以外取ってこない想定)
      else if (item.co2_concentration != null) {
        // X軸に日時情報を代入
        this.x_value = item.date_time;
        // Y軸に「CO2濃度」情報を代入
        this.y_value = item.co2_concentration;

        // console.log("CO2濃度のグラフを生成します");
      }
      // 8.「光合成速度」の情報が返ってきている場合(仮、クエリは必要な情報以外取ってこない想定)
      else if (item.photosynthesis_rate != null) {
        // X軸に日時情報を代入
        this.x_value = item.date_time;
        // Y軸に「光合成速度」情報を代入
        this.y_value = item.photosynthesis_rate;

        // console.log("光合成速度のグラフを生成します");
      }
      // 総収量情報(gross_harvest)が返ってきている場合は、返ってきたパラメーターの情報を代入
      else if (item.weight != null) {
        // X軸に日付情報を代入
        this.x_value = item.date;
        // Y軸に「重量」情報を代入
        this.y_value = item.weight;

        // console.log("重量のグラフを生成します");
      }
      // 計測データ情報(growth_data)が返ってきている場合は、返ってきたパラメーターの情報を代入
      // 1. 「SPAD値」の情報が返ってきている場合(仮、クエリは必要な情報以外取ってこない想定)
      else if (item.quantity_4 != null) {
        // X軸に日付情報を代入
        this.x_value = item.date;
        // Y軸に「SPAD値」情報を代入
        this.y_value = item.quantity_4;

        // console.log("SPAD値のグラフを生成します");
      }
      // 2. 「茎長(length_1)」の情報が返ってきている場合(仮、クエリは必要な情報以外取ってこない想定)
      else if (item.length_1 != null) {
        // X軸に日付情報を代入
        this.x_value = item.date;
        // Y軸に「茎長」情報を代入
        this.y_value = item.length_1;

        // console.log("茎長のグラフを生成します");
      }
      // 3. 「葉長(length_2)」の情報が返ってきている場合(仮、クエリは必要な情報以外取ってこない想定)
      else if (item.length_2 != null) {
        // X軸に日付情報を代入
        this.x_value = item.date;
        // Y軸に「葉長」情報を代入
        this.y_value = item.length_2;

        // console.log("葉長のグラフを生成します");
      }
      // 4. 「葉幅(length_3)」の情報が返ってきている場合(仮、クエリは必要な情報以外取ってこない想定)
      else if (item.length_3 != null) {
        // X軸に日付情報を代入
        this.x_value = item.date;
        // Y軸に「葉幅」情報を代入
        this.y_value = item.length_3;

        // console.log("葉幅のグラフを生成します");
      }
      // 5. 「茎径(diameter)」の情報が返ってきている場合(仮、クエリは必要な情報以外取ってこない想定)
      else if (item.diameter != null) {
        // X軸に日付情報を代入
        this.x_value = item.date;
        // Y軸に「茎径」情報を代入
        this.y_value = item.diameter;

        // console.log("茎径のグラフを生成します");
      }
      // 6. 「開花段数(quantity_1)」の情報が返ってきている場合(仮、クエリは必要な情報以外取ってこない想定)
      else if (item.quantity_1 != null) {
        // X軸に日付情報を代入
        this.x_value = item.date;
        // Y軸に「開花段数」情報を代入
        this.y_value = item.quantity_1;

        // console.log("開花段数のグラフを生成します");
      }
      // 7. 「全葉数(quantity_2)」の情報が返ってきている場合(仮、クエリは必要な情報以外取ってこない想定)
      else if (item.quantity_2 != null) {
        // X軸に日付情報を代入
        this.x_value = item.date;
        // Y軸に「全葉数」情報を代入
        this.y_value = item.quantity_2;

        // console.log("全葉数のグラフを生成します");
      }
      // 8. 「収穫段数(quantity_3)」の情報が返ってきている場合(仮、クエリは必要な情報以外取ってこない想定)
      else if (item.quantity_3 != null) {
        // X軸に日付情報を代入
        this.x_value = item.date;
        // Y軸に「収穫段数」情報を代入
        this.y_value = item.quantity_3;

        // console.log("収穫段数のグラフを生成します");
      }
      // 養液情報(nutrient_solution)が返ってきている場合は、返ってきたパラメーターの情報を代入
      // 1. 「カルシウム(ca)」の情報が返ってきている場合(仮、クエリは必要な情報以外取ってこない想定)
      // データの中で、数値ではなく、文字列が格納されている場合は、そのデータはグラフにプロットしないようにする
      // caの場合、「Ur」と「None」の文字列が含まれていることがある
      else if (item.ca != null) {
        // 返ってきたデータが数値の場合には、Y軸に数値をセット
        if(item.ca != "Ur" && item.ca != "None")
        {
          // X軸に日付情報を代入
          this.x_value = item.date;
          // Y軸に「カルシウム」情報を代入
          this.y_value = item.ca; 
        }
        // そうでなく、文字列の場合には、Y軸に空の情報をセット
        else
        {
          // X軸に日付情報を代入
          this.x_value = item.date;
          // Y軸に空の情報を代入
          this.y_value = undefined; 
        }
        // console.log("カルシウムのグラフを生成します");
      }
      // 2. 「カリウム(k)」の情報が返ってきている場合(仮、クエリは必要な情報以外取ってこない想定)
      else if (item.k != null) {
        // 返ってきたデータが数値の場合には、Y軸に数値をセット
        if(item.k != "None" && item.k != "Ur" && item.k != "＊")
        {
          // X軸に日付情報を代入
          this.x_value = item.date;
          // Y軸に「カリウム」情報を代入
          this.y_value = item.k; 
        }
        // そうでなく、文字列の場合には、Y軸に空の情報をセット
        else
        {
          // X軸に日付情報を代入
          this.x_value = item.date;
          // Y軸に空の情報を代入
          this.y_value = undefined; 
        }
        // console.log("カリウムのグラフを生成します");
      }
      // 3. 「窒素(no3)」の情報が返ってきている場合(仮、クエリは必要な情報以外取ってこない想定)
      else if (item.no3 != null) {
        // 返ってきたデータが数値の場合には、Y軸に数値をセット
        if(item.no3 != "None" && item.no3 != "14r0")
        {
          // X軸に日付情報を代入
          this.x_value = item.date;
          // Y軸に「窒素」情報を代入
          this.y_value = item.no3;
        }
        // そうでなく、文字列の場合には、Y軸に空の情報をセット
        else
        {
          // X軸に日付情報を代入
          this.x_value = item.date;
          // Y軸に空の情報を代入
          this.y_value = undefined;
        }
        // console.log("窒素のグラフを生成します");
      }
      // 4. 「ph(pH)」の情報が返ってきている場合(仮、クエリは必要な情報以外取ってこない想定)
      // データの中で、数値ではなく、文字列が格納されている場合は、そのデータはグラフにプロットしないようにする
      // pHの場合、「NA」と「None」の文字列が含まれていることがある
      else if (item.ph != null) {
        // 返ってきたデータが数値の場合には、Y軸に数値をセット
        if(item.ph != "NA" && item.ph != "None")
        {
          // X軸に日付情報を代入
          this.x_value = item.date;
          // Y軸に「pH」情報を代入
          this.y_value = item.ph; 
        }
        // そうでなく、文字列の場合には、Y軸に空の情報をセット
        else
        {
          // X軸に日付情報を代入
          this.x_value = item.date;
          // Y軸に空の情報を代入
          this.y_value = undefined;           
        }
        // console.log("pHのグラフを生成します");
      }
      // 5. 「ec(EC値)」の情報が返ってきている場合(仮、クエリは必要な情報以外取ってこない想定)
      else if (item.ec != null) {
        // 返ってきたデータが数値の場合には、Y軸に数値をセット
        if(item.ec != "None")
        {
          // X軸に日付情報を代入
          this.x_value = item.date;
          // Y軸に「EC値」情報を代入
          this.y_value = item.ec; 
        }
        // そうでなく、文字列の場合には、Y軸に空の情報をセット
        else
        {
          // X軸に日付情報を代入
          this.x_value = item.date;
          // Y軸に空の情報を代入
          this.y_value = undefined;    
        }
        // console.log("EC値のグラフを生成します");
      }
      // 灌水量情報(liquid_supply_management)が返ってきている場合は、返ってきたパラメーターの情報を代入
      // 灌水量の変化量に対する問について、beforeとafterの両方が返ってきている場合
      // ただし、Y軸に描画させるデータは、「after」から「before」をひいたものを描画することとする
      else if(item.before != null && item.after != null)
      {
        // X軸に日付情報を代入
        this.x_value = item.date;

        // Y軸に灌水量の変更量(after - before)を代入

        // mlを削除後、Int型に変換
        const beforeValue = parseInt(item.before.replace('ml', ''));
        // console.log("beforeValue", beforeValue);

        const afterValue = parseInt(item.after.replace('ml', ''));
        // console.log("afterValue", afterValue);

        // どのくらい灌水量を変更したのかを「after - before」で計算
        const change = afterValue - beforeValue;
        // console.log("計算後の灌水量の変化量", change);

        // Y軸に灌水量の変化量を代入
        this.y_value = change;

        // console.log("灌水量データの水位の変化量のグラフ座標情報を設定");
      }
      // 灌水量データのみの問い合わせがあった場合(変更前のデータではなく、変更後のデータが返ってている場合)
      else if(item.before == undefined && item.after != undefined)
      {
        // X軸に日付情報を代入
        this.x_value = item.date;
        // Y軸に灌水量データ(変更後)を代入
        const afterValue = item.after.replace('ml', '');
        // console.log("afterValue", afterValue);

        this.y_value = afterValue;

        // console.log("灌水量データ(変更後)のグラフ座標情報を設定");
      }
    },
    // ApexChartsで描画させるグラフの配列を用意
    setGraph(graphType) {
      // 折れ線グラフ or 棒グラフパターンの場合
      if(graphType != "pie")
      {
        const graphArray = {
          options: {
            chart: {
              // ツールバーの設定
              toolbar: {
                // 非表示にする
                show: false,
              },
            },
            // グラフのX軸設定
            xaxis: {
              // X軸の値を格納
              categories: [],
            },
            // グラフのマーカー設定
            markers: {
              // マーカーのサイズを指定(0の場合、非表示になる)
              size: 1,
            },
            // ツールチップ設定
            tooltip: {
              // ツールチップを有効
              enabled: true,
              // ツールチップのデザインを黒基調に設定
              theme: "dark",
            },
            dataLabels: {
              // データラベルを非表示にする
              enabled: false,
            },
          },
          // グラフのY軸設定
          series: [
            {
              // Y軸データの名称を指定(この部分は現在、空白にしている)
              name: "",
              // Y軸の値
              data: [],
            },
          ],
        }; 
        // 新しいグラフ生成用のグラフオブジェクトを配列に追加
        // 1つのグラフとして描画可能
        this.chart.graphArrayData.push(graphArray);
        // console.log("新しいグラフセット", this.chart.graphArrayData);
      }
      // 円グラフパターンの場合
      else
      {
        const graphArray = {
          options: {
            chart: {
              // ツールバーの設定
              toolbar: {
                // 非表示にする
                show: false,
              },
            },
            // グラフのX軸設定
            xaxis: {
              // X軸の値を格納
              categories: [],
            },
            // グラフのマーカー設定
            markers: {
              // マーカーのサイズを指定(0の場合、非表示になる)
              size: 1,
            },
            // ツールチップ設定
            tooltip: {
              // ツールチップを有効
              enabled: true,
              // ツールチップのデザインを黒基調に設定
              theme: "dark",
            },
            dataLabels: {
              // データラベルを表示する(円グラフのみ)
              enabled: true,
              formatter: function (val) {
                // 小数点以下なしでパーセント表示
                return Math.round(val) + "%"; 
              },
            },
            // 各クラス名をここに格納(円グラフ用)
            labels: [],
          },
          // グラフのY軸設定
          series: [
            {
              // Y軸データの名称を指定(この部分は現在、空白にしている)
              name: "",
              // Y軸の値
              data: [],
            },
          ],
        }; 
        // 新しいグラフ生成用のグラフオブジェクトを配列に追加
        // 1つのグラフとして描画可能
        this.chart.graphArrayData.push(graphArray);
        // console.log("新しいグラフセット", this.chart.graphArrayData);
        // console.log("円グラフ設定をセット")
      }
      // グラフのインデックスを1増やす
      this.graphIndex += 1;
      // console.log("graphIndex", this.graphIndex);
    },
    // グラフデータが格納されている配列をリセット
    resetGraphArray()
    {
      // リストの中身をリセット
      this.chart.graphArrayData = [];
      this.chart.graphArrayData.length = 0;
      
      // リストのインデックスをリセット
      this.graphIndex = -1;
      // console.log("グラフのリストの中身をリセットしました");
      // console.log("リセット後のgraphArrayDataの中身", this.chart.graphArrayData);
    }
  },
};
</script>

<style>
/*画面全体*/
html,
body {
  padding: 0;
  margin: 0;
  background-color: rgb(136, 174, 82);
}

/*ChatBotのタイトル*/
.title {
  color: white;
  text-align: center;
}

/*サインアウトボタン*/
.signoutbtn {
  /*絶対位置指定*/
  position: absolute;
  /*右端に配置*/
  right: 10px;
  /*上端に配置*/
  top: 20px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

/*三本線のアイコンボタン*/
.threebaricon {
  position: absolute;
  left: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

/*三本線アイコン*/
.threebar-icon {
  height: 20px;
  width: 20px;
}

/*メッセージ入力フォーム全体設定*/
.generalformposition {
  /*positionをabsoluteにする*/
  position: absolute;
  /*画面下に固定*/
  top: 90%;
  /*真ん中に固定*/
  /* 左右中央に */
  left: 50%;
  /* 幅の50%分左に移動して中央配置 */
  transform: translateX(-50%);
  overflow-x: hidden;
}

/*メッセージ入力用フィールド*/
.input-container {
  /*要素を横並びに設定*/
  display: flex;
  position: relative;
  /* 上部の余白を追加 */
  margin-top: 10px;
}

/*メッセージ入力フォーム*/
textarea {
  width: 700px;
  border-radius: 10px;
  font-size: 16px;
  /*入力フォームの枠線を設定*/
  border: 1px solid black;
  /*改行されたときの入力フォームの最大・最小値を指定*/
  min-height: 42px;
  max-height: 200px;
  /*ユーザーによるリサイズを制限*/
  resize: none;
  overflow-y: auto;
  padding-left: 15px;
}

/*入力文字列の位置を設定*/
textarea {
  /*placeholderと入力開始位置を設定*/
  padding-left: 15px;
  padding-bottom: 0;
  /*改行した時の文字列の間隔を設定*/
  line-height: 1.3;
}

/*メッセージ送信ボタン(飛行機アイコン仕様)*/
.sendbtn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  /*初回、画面下の中心にあるメッセージ入力フォームを基準にして相対positionを設定*/
  position: relative;
  /*メッセージ入力フォームの右端から50pxの部分に飛行機ボタンを配置*/
  right: 50px;
}

/*紙飛行機アイコン*/
.paperfly-icon {
  height: 30px;
  width: 30px;
}

/*メッセージ表示部分(可変)全体*/
.message-container {
  /*子要素をフレックスボックスとして指定*/
  display: flex;
  /* 縦に積み重ねる */
  flex-direction: column;
  /* コンテナの高さを制限 */
  max-height: 80vh;
  /* 縦方向のスクロールを許可 */
  overflow-y: auto;
  width: 100%;
  overflow-x: hidden;
}

/*メッセージ繰り返し出力部分*/
.message {
  /* メッセージをブロックレベルに */
  display: block;
  width: 100%;
  margin-bottom: 10px;
  /*左揃えに設定*/
  text-align: left;
}

/*アイコン表示設定(親要素)*/
.messageicon-container {
  /*相対位置を指定*/
  position: relative;
}

/*アイコン表示設定*/
.message-icon {
  /*縦横の幅を指定*/
  height: 30px;
  width: 30px;
  /* 絶対位置を指定 */
  position: absolute;
  /* 23px下に移動 */
  padding-top: 23px;
  /*左端からの幅を指定*/
  padding-left: 27%;
}

/*ゴミ箱ボタン表示設定(ユーザー側のみ)*/
.trashicon {
  /* 絶対位置を指定 */
  position: absolute;
  /* 23px下に移動 */
  padding-top: 30px;
  /*右端からの幅を指定*/
  right: 3%;
  /*ボタンを透明に設定*/
  background-color: transparent;
  border: none;
  cursor: pointer;
}

/*ゴミ箱アイコン表示設定(ユーザー側のみ)*/
.trash-icon {
  /*縦横の幅を指定*/
  height: 20px;
  width: 20px;
}

/*メッセージ出力部分*/
.message-text {
  /* display: block; */
  width: 100%;
  /*ブロック内における上下左右の余白を設定*/
  padding: 30px;
  /*文字の折り返しを設定*/
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  /*フォント色を指定*/
  color: white;
  /*フォントサイズを指定*/
  font-size: 17.5px;
  /*左端からの余白部分を設定*/
  padding-left: 31%;
  /*右端からの余白部分を設定*/
  padding-right: 69%;
  /*最大幅を半角65文字分に設定(これを超えると、文字列が折り返される)*/
  max-width: 65ch;
  /* 改行を保持 */
  white-space: pre-wrap;
}

/*ユーザメッセージ表示部分(botの表示部分のみ、背景色を変更)*/
.user .message-text {
  /* ユーザー側メッセージの背景色 */
  background-color: rgb(136, 174, 82);
}

/*botメッセージ表示部分(botの表示部分のみ、背景色を変更)*/
.bot .message-text {
  /* bot側メッセージの背景色 */
  background-color: rgb(160, 185, 126);
}

/*botモードかつ、ぐるぐる表示部分(botの表示部分のみ、背景色を変更、かつぐるぐるも表示)*/
.bot .loader {
  /* bot側メッセージの背景色 */
  background-color: rgb(160, 185, 126);
}

/*ぐるぐる表示部分*/
.loader {
  /*色付け部分を画面全体に伸ばす*/
  width: 100%;
  /*ブロック内における上下左右の余白を設定*/
  padding: 30px;
  /*左端からの余白部分を設定*/
  padding-left: 31%;
  /*右端からの余白部分を設定*/
  padding-right: 69%;
}

/*AI Searchから取得した画像の大きさ設定*/
.image-size {
  /*縦を50%に縮小*/
  height: 50%;
  /*横を50%に縮小*/
  width: 50%;
}

/*デザインA(スマートフォン)*/
/*スマートフォンサイズ、599px以下の時に適用するCSSを設定*/
/*変化させたいclassに変更を加えており、その他定義していないclassについてはデフォルトのCSSを適用している*/
@media screen and (max-width: 599px) {
  .chatbot-container {
    /* 1カラムに変更 */
    grid-template-columns: 1fr;
    /* 行を自動調整 */
    grid-template-rows: auto auto auto;
  }

  /*スマホサイズの場合には、アイコン、テキストのフォントを左側に寄せてサイズを小さくする*/

  /*サイドバーボタンのアイコン*/
  .threebar-icon {
    width: 15px;
    height: 15px;
  }

  /*チャット画面中のタイトル*/
  .title {
    font-size: 16px;
    text-align: center;
  }

  /*サインアウトボタン*/
  .signoutbtn {
    position: absolute;
    top: 10px;
    right: 5px;
    font-size: 10px;
  }

  /*メッセージアイコン*/
  .message-icon {
    /*縦横の幅を指定*/
    height: 25px;
    width: 25px;
    /* 絶対位置を指定 */
    position: absolute;
    /* 23px下に移動 */
    padding-top: 23px;
    /*左端からの幅を指定*/
    padding-left: 3%;
  }

  /*メッセージ表示領域*/
  .message-text {
    width: 100%;
    /*ブロック内における上下左右の余白を設定*/
    padding: 30px;
    /*文字の折り返しを設定*/
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    /*フォント色を指定*/
    color: white;
    /*フォントサイズを指定*/
    font-size: 15px;
    /*左端からの余白部分を設定*/
    padding-left: 15%;
    /*右端からの余白部分を設定*/
    padding-right: 69%;
    /*最大幅を半角30文字分に設定(これを超えると、文字列が折り返される)*/
    max-width: 30ch;
    /* 改行を保持 */
    white-space: pre-wrap;
  }

  /*メッセージ入力フォーム(全体)*/
  .generalformposition {
    /*100%にすると下の幅が広がってしまい、無駄なスクロールが増えるため90%に設定*/
    width: 90%;
  }

  /*メッセージ入力フォーム(子要素(送信ボタン含める))*/
  .input-container {
    position: relative;
    /*左側から右側に20px寄せる*/
    left: 20px;
  }

  /*LLMからの回答待ち、ぐるぐる表示*/
  .loader {
    /*左端からの余白部分を設定*/
    padding-left: 15%;
    /*右端からの余白部分を設定*/
    padding-right: 69%;
  }

  /*AI Searchから取得した画像の大きさ設定*/
  .image-size {
    /*縦を100%に拡大*/
    height: 100%;
    /*横を100%に拡大*/
    width: 100%;
  }
}

/*デザインB(タブレット縦)*/
/*タブレット(縦)サイズ、600px以上、1079px以下の時に適用するCSSを設定*/
/*変化させたいclassに変更を加えており、その他定義していないclassについてはデフォルトのCSSを適用している*/
@media screen and (min-width: 600px) and (max-width: 1079px) {

  /*タブレット(縦)サイズの場合には、アイコン、テキストのフォントを左側に寄せるのみ*/

  /*メッセージアイコン*/
  .message-icon {
    /* 絶対位置を指定 */
    position: absolute;
    /* 23px下に移動 */
    padding-top: 23px;
    /*左端からの幅を指定*/
    padding-left: 5%;
  }

  /*メッセージ表示領域*/
  .message-text {
    /*左端からの余白部分を設定*/
    padding-left: 13%;
    /*右端からの余白部分を設定*/
    padding-right: 69%;
    /*最大幅を半角50文字分に設定(これを超えると、文字列が折り返される)*/
    max-width: 50ch;
  }

  /*メッセージ入力フォーム(全体)*/
  .generalformposition {
    /*100%にすると無駄なスクロールが増えるため90%に設定*/
    width: 90%;
  }

  /*LLMからの回答待ち、ぐるぐる表示*/
  .loader {
    /*左端からの余白部分を設定*/
    padding-left: 13%;
    /*右端からの余白部分を設定*/
    padding-right: 69%;
  }

  /*AI Searchから取得した画像の大きさ設定*/
  .image-size {
    /*縦を100%に拡大*/
    height: 100%;
    /*横を100%に拡大*/
    width: 100%;
  }  
}

/*デザインC(PC/タブレット横)*/
/*1080px以上になった場合には、デフォルトで定義したCSS通りに描画*/

/*高さが基準のピクセルよりも下回った場合には、メッセージ入力フォームの位置を上にずらす*/

/*高さが600pxを下回った場合*/
@media screen and (max-height: 600px) {
  /*メッセージ入力フォーム全体設定*/
  .generalformposition {
    /*画面上からの位置を上にずらす*/
    top: 88%;
  }

  /*メッセージ入力用フィールド*/
  .input-container {
    /*要素を横並びに設定*/
    display: flex;
    position: relative;
    /* 上部の余白を減らす */
    margin-top: 5px;
  }
}

/*高さが420pxを下回った場合*/
@media screen and (max-height: 420px) {
  /*メッセージ入力フォーム全体設定*/
  .generalformposition {
    /*画面上からの位置を上にずらす*/
    top: 86%;
  }

  /*メッセージ入力用フィールド*/
  .input-container {
    /*要素を横並びに設定*/
    display: flex;
    position: relative;
    /* 上部の余白を減らす */
    margin-top: 3px;
  }
}

/*高さが350pxを下回った場合*/
@media screen and (max-height: 350px) {
  /*メッセージ入力フォーム全体設定*/
  .generalformposition {
    /*画面上からの位置を上にずらす*/
    top: 84%;
  }

  /*メッセージ入力用フィールド*/
  .input-container {
    /*要素を横並びに設定*/
    display: flex;
    position: relative;
    /* 上部の余白を減らす */
    margin-top: 1px;
  }
}

/*高さが300pxを下回った場合*/
@media screen and (max-height: 300px) {
  /*メッセージ入力フォーム全体設定*/
  .generalformposition {
    /*画面上からの位置を上にずらす*/
    top: 82%;
  }

  /*メッセージ入力用フィールド*/
  .input-container {
    /*要素を横並びに設定*/
    display: flex;
    position: relative;
    /* 上部の余白を廃止 */
    margin-top: 0px;
  }
}

/*高さが255pxを下回った場合*/
@media screen and (max-height: 255px) {
  /*メッセージ入力フォーム全体設定*/
  .generalformposition {
    /*画面上からの位置を上にずらす*/
    top: 80%;
  }

  /*メッセージ入力用フィールド*/
  .input-container {
    /*要素を横並びに設定*/
    display: flex;
    position: relative;
    /* 上部の余白を廃止 */
    margin-top: 0px;
  }
}

/*高さが250pxを下回った場合*/
@media screen and (max-height: 250px) {
  /*メッセージ入力フォーム全体設定*/
  .generalformposition {
    /*画面上からの位置を上にずらす*/
    top: 78%;
  }

  /*メッセージ入力用フィールド*/
  .input-container {
    /*要素を横並びに設定*/
    display: flex;
    position: relative;
    /* 上部の余白を廃止 */
    margin-top: 0px;
  }
}

/*高さが220pxを下回った場合*/
@media screen and (max-height: 220px) {
  /*メッセージ入力フォーム全体設定*/
  .generalformposition {
    /*画面上からの位置を上にずらす*/
    top: 76%;
  }

  /*メッセージ入力用フィールド*/
  .input-container {
    /*要素を横並びに設定*/
    display: flex;
    position: relative;
    /* 上部の余白を廃止 */
    margin-top: 0px;
  }
}

/*高さが200pxを下回った場合*/
@media screen and (max-height: 200px) {
  /*メッセージ入力フォーム全体設定*/
  .generalformposition {
    /*画面上からの位置を上にずらす*/
    top: 74%;
  }

  /*メッセージ入力用フィールド*/
  .input-container {
    /*要素を横並びに設定*/
    display: flex;
    position: relative;
    /* 上部の余白を廃止 */
    margin-top: 0px;
  }
}

/*高さが180pxを下回った場合*/
@media screen and (max-height: 180px) {
  /*メッセージ入力フォーム全体設定*/
  .generalformposition {
    /*画面上からの位置を上にずらす*/
    top: 72%;
  }

  /*メッセージ入力用フィールド*/
  .input-container {
    /*要素を横並びに設定*/
    display: flex;
    position: relative;
    /* 上部の余白を廃止 */
    margin-top: 0px;
  }
}

/*高さが170pxを下回った場合*/
@media screen and (max-height: 170px) {
  /*メッセージ入力フォーム全体設定*/
  .generalformposition {
    /*画面上からの位置を上にずらす*/
    top: 70%;
  }

  /*メッセージ入力用フィールド*/
  .input-container {
    /*要素を横並びに設定*/
    display: flex;
    position: relative;
    /* 上部の余白を廃止 */
    margin-top: 0px;
  }
}

/*高さが155pxを下回った場合*/
@media screen and (max-height: 155px) {
  /*メッセージ入力フォーム全体設定*/
  .generalformposition {
    /*画面上からの位置を上にずらす*/
    top: 68%;
  }

  /*メッセージ入力用フィールド*/
  .input-container {
    /*要素を横並びに設定*/
    display: flex;
    position: relative;
    /* 上部の余白を廃止 */
    margin-top: 0px;
  }
}

/*高さが145pxを下回った場合*/
@media screen and (max-height: 145px) {
  /*メッセージ入力フォーム全体設定*/
  .generalformposition {
    /*画面上からの位置を上にずらす*/
    top: 66%;
  }

  /*メッセージ入力用フィールド*/
  .input-container {
    /*要素を横並びに設定*/
    display: flex;
    position: relative;
    /* 上部の余白を廃止 */
    margin-top: 0px;
  }
}

/*高さが135pxを下回った場合*/
@media screen and (max-height: 135px) {
  /*メッセージ入力フォーム全体設定*/
  .generalformposition {
    /*画面上からの位置を上にずらす*/
    top: 64%;
  }

  /*メッセージ入力用フィールド*/
  .input-container {
    /*要素を横並びに設定*/
    display: flex;
    position: relative;
    /* 上部の余白を廃止 */
    margin-top: 0px;
  }
}

/*高さが130pxを下回った場合*/
@media screen and (max-height: 130px) {
  /*メッセージ入力フォーム全体設定*/
  .generalformposition {
    /*画面上からの位置を上にずらす*/
    top: 62%;
  }

  /*メッセージ入力用フィールド*/
  .input-container {
    /*要素を横並びに設定*/
    display: flex;
    position: relative;
    /* 上部の余白を廃止 */
    margin-top: 0px;
  }
}

/*高さが120pxを下回った場合*/
@media screen and (max-height: 120px) {
  /*メッセージ入力フォーム全体設定*/
  .generalformposition {
    /*画面上からの位置を上にずらす*/
    top: 60%;
  }

  /*メッセージ入力用フィールド*/
  .input-container {
    /*要素を横並びに設定*/
    display: flex;
    position: relative;
    /* 上部の余白を廃止 */
    margin-top: 0px;
  }
}

/*高さが115pxを下回った場合*/
@media screen and (max-height: 115px) {
  /*メッセージ入力フォーム全体設定*/
  .generalformposition {
    /*画面上からの位置を上にずらす*/
    top: 58%;
  }

  /*メッセージ入力用フィールド*/
  .input-container {
    /*要素を横並びに設定*/
    display: flex;
    position: relative;
    /* 上部の余白を廃止 */
    margin-top: 0px;
  }
}

/*高さが110pxを下回った場合*/
@media screen and (max-height: 110px) {
  /*メッセージ入力フォーム全体設定*/
  .generalformposition {
    /*画面上からの位置を上にずらす*/
    top: 56%;
  }

  /*メッセージ入力用フィールド*/
  .input-container {
    /*要素を横並びに設定*/
    display: flex;
    position: relative;
    /* 上部の余白を廃止 */
    margin-top: 0px;
  }
}

/* ドロップダウンメニューの全体設定 */
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown button {
  padding: 10px;
  background-color: #32a89d;
  color: white;
  border: none;
  cursor: pointer;
  /* ボタンの幅を指定 */
  width: 110px;
}

/* ドロップダウンメニュー */
.dropdown ul {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  list-style-type: none;
  padding: 5px 0;
  margin: 0;
  z-index: 1000;
  /* ボタンの幅を指定 */
  width: 110px;
}

/* リスト項目のスタイル */
.dropdown li {
  color: black;
  /* 上下のパディングを少し縮め */
  padding: 6px 16px;
  /* フォントサイズを12pxに設定 */
  font-size: 13px;
  cursor: pointer;
}

/* ホバー時のスタイル */
.dropdown li:hover {
  background-color: #f1f1f1;
}

</style>
