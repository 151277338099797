<template>
  <div class="sidebar">
    <!--サイドバーの内容をここに追加-->
    <!--サイドバー閉じるボタン-->
    <button class="sidebar-close-btn" @click="$emit('close-sidebar')">✕</button>
    <!--サイドバーのタイトル-->
    <h2 class="sidebar-title">営農支援AI(仮称)</h2>
    <p>前回ログイン：{{ last_login_time }}</p>
    <!--「New Chat」ボタン-->
    <button class="newchat-button" @click="createChatButton">New Chat</button>

    <!--余白確保エリア-->
    <div class="margin-area"></div>

    <!--サイドバーのボタン類-->
    <div class="sidebar-button">
      <div v-if="isSideBarLoading" class="sidebar-loading">
        Loading...
      </div>
      <div v-else>
        <button
        v-for="(conversation, index) in conversations"
        :key="conversation.chat_session_id"
        :class="{ 'create-button': true, active: isActive(index) }"
        @click="selectButton(index, conversation.chat_session_id)"
        @mouseenter="showButton(index)"
        @mouseleave="hideButton(index)"
        >
          {{ checkCharacterNumber(conversation.oldest_question, index) }}

          <!--ゴミ箱ボタンを配置-->
          <button
            class="trashicon"
            v-show="showTrashButton[index]"
            @click="deleteItem(conversation.chat_session_id)"
          >
            <img src="@/assets/TrashBox.png" alt="TrashBox.png" class="trash-icon" />
          </button>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SideBar",
  props: {
    // App.vueから初回最新データ読み込みフラグを受け取る
    isInitialize: Boolean,
    // App.vueからローディングフラグを取得
    loading: Boolean,
    // App.vueから初回ローディング画面表示フラグを取得
    isLoading: Boolean,
    // App.vueから現在選択中の最新の会話セッションIDを受け取る
    conversation_id: Number,
    // App.vueからバックアップされた最新のインデックスを取得
    activeindex_backup: Number,
    // App.vueからユーザーIDを取得
    userId: String,
    // App.vueからIDトークンを取得
    idToken: String,
    // App.vueから最終ログイン日時を取得
    last_login_time: String,
  },
  data() {
    return {
      // CosmosDBから取得したセッションIDごとの一番古いプロンプトデータを格納するリスト
      conversations: [],
      // CosmosDBから取得した全ての会話データを格納するリスト
      conversations_all: [],
      // 最初のボタンをアクティブにデフォルト設定
      activeIndex: 0,
      // 会話セッションID格納用変数
      conversation_sessionid: 1,
      // ゴミ箱ボタン表示判定フラグ
      showTrashButton: {},
      // サイドバー、「Loading」表示判定フラグ
      isSideBarLoading: false,

      // Azure Function接続先IP(バックエンド)
      function_ip: process.env.VUE_APP_API_URL,
    };
  },
  // サイドバーが表示されるたびに処理を実行
  mounted() {
    //ユーザーIDが返ってきている場合のみ、実行(初回は実行しない)
    if (this.userId != "") {
      // CosmosDBへの最新データ問い合わせ
      this.fetchConversations();
      // サイドバー側の初回「Loading」表示フラグをオン(SideBar.vueがマウントされた場合のみ表示)
      this.isSideBarLoading = true;
    }
  },
  methods: {
    // 「New Chat」ボタン押下処理
    createChatButton() {
      // ローディング中の場合は「New Chat」ボタン押下を不可能とする
      if(this.loading)
      {
        // console.log("ローディング中のため、「New Chat」ボタン押下は不可能");
        return;
      }

      // サイドバーボタンの一番初回のセッションIDを取得後、1つIDを新規追加
      const add_sesstionId = this.conversations[0].chat_session_id + 1;
      this.conversation_sessionid = add_sesstionId;
      // console.log("足されたセッションID", this.conversation_id);

      // サイドバーボタンのテキスト表示用のリストに先頭からデータを追加
      this.conversations.unshift({
        chat_session_id: this.conversation_sessionid,
        oldest_question: "New Conversation",
      });
      // console.log("conversations", this.conversations);

      // フィルタリングされたデータと会話セッションIDを「App.vue」に渡す
      this.$emit("session-selected", "", this.conversation_sessionid);

      // 前回表示させていたゴミ箱ボタンを非表示(セーフティー)
      this.showTrashButton[this.activeIndex] = false;
      // 一番上のアクティブインデックスを設定して、色付けする
      this.activeIndex = 0;
      // 「New Chat」ボタン生成時には、ゴミ箱ボタンを表示させない
      this.showTrashButton[0] = false;
      // activeIndexをApp.vueにバックアップとして退避(サイドバーが閉じられると、SideBar.vue側の値が初期化されるため)
      this.$emit("index-backup", this.activeIndex);
    },
    // 再度バーボタンがアクティブかどうかを判定
    isActive(index) {
      // activeIndexと一致するかどうか判定
      // 一致する場合は「true」を返し、そうでない場合は「false」を返す
      return this.activeIndex === index;
    },
    // 今、選択されているボタンのインデックスを取得して、activeクラスに渡す
    selectButton(index, sessionId) {
      // ローディング中はサイドバーの会話選択ボタンを押下不可能とする
      if(this.loading)
      {
        // console.log("リクエスト中のため、会話選択ボタンの押下不可能");
        return;
      }

      // 前回、クリックされた後表示されたサイドバーのボタンは削除
      this.showTrashButton[this.activeIndex] = false;

      // activeIndexを更新
      this.activeIndex = index;
      // console.log("index", index);
      // console.log("sessionId", sessionId);

      // activeIndexをApp.vueにバックアップとして退避(サイドバーが閉じられると、SideBar.vue側の値が初期化されるため)
      this.$emit("index-backup", this.activeIndex);

      // データが存在する場合のみ、ゴミ箱ボタンを表示
      if (this.conversations[index].oldest_question != "New Conversation") {
        this.showTrashButton[index] = true;
      }

      // sesstionIDが登録済の場合
      if (sessionId != undefined) {
        // 選択されたセッションIDの会話履歴を取り出して、リストに格納

        if (this.conversations_all != undefined) {
          // 会話セッションIDごとに、会話履歴を抽出
          const conversation_idhistory = this.conversations_all.filter(
            (msg) => msg.chat_session_id === sessionId
          );
          // console.log(conversation_idhistory);

          // 配列の形式を指定し、親に渡すための準備を行う
          const formattedMessages = conversation_idhistory.map((msg) => ({
            // ユーザーの質問
            question: { text: msg.question, type: "user" },
            // tsuzumiからの回答
            answer: { text: msg.answer, type: "bot" },
            // RAGから返ってきた画像URL
            imageUrl: msg.imageUrl,
            // CosmosDBから返ってきたDBデータ(グラフ描画用)
            graphData: msg.graphData,
            // CosmosDBから返ってきたグラフ種別(グラフ種別判断用)
            graphType: msg.graphType
          }));
          // console.log("formattedMessages", formattedMessages);

          // 抽出されたデータと会話セッションIDを「App.vue」に渡す
          this.$emit("session-selected", formattedMessages, sessionId);
        } else {
          // ボタンテキストが「New Conversation」の場合(質問したい事項を～のみ表示されている状態)は、そのままセッションIDだけを返す
          // フィルタリングされたデータと会話セッションIDを「App.vue」に渡す
          this.$emit("session-selected", "", sessionId);
        }
      } else {
        // 「New Conversation」ボタンが1つのみの場合は、セッションIDを「1」で初期化する
        // フィルタリングされたデータと会話セッションIDを「App.vue」に渡す
        this.$emit("session-selected", "", 1);
        // console.log("データ未登録です");
      }
    },
    // 会話履歴の問い合わせ
    fetchConversations() {
      // Azure Funcstionsの接続先を指定
      const api_url = this.function_ip + "&action=GetAllConversations";

      // セッションストレージからCSRFトークンを取得
      const csrfToken = sessionStorage.getItem("csrfToken");
      // ユーザートークンをCookieから取得
      const user_token = this.$cookies.get('user_token');
      // console.log("Cookieの値をゲットしました");

      fetch(api_url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // IDトークンをヘッダに含める
          Authorization: `Bearer ${this.idToken}`,
          // CSRFトークンをヘッダーに含める
          "X-CSRF-TOKEN": csrfToken,
          // ユーザーIDをヘッダーに含める
          "X-USER-ID": this.userId,
          // ユーザーIDごとに振り出されたユーザートークンをヘッダーに含める
          "X-USER-TOKEN": user_token
        },
      })
        .then((responce) => responce.json())
        .then((data) => {
          // CosmosDBからの会話セッションごとの一番古いプロンプトデータを取得
          // CosmosDBからの全データではない
          this.conversations = data.oldest_question;
          // console.log("conversations", this.conversations.oldest_question);

          // CosmosDBからの全ての会話データを取得
          this.conversations_all = data.conversation_history;
          // console.log("conversations_all", this.conversations_all);

          // oldest_questionの1番目の中身チェック用の変数を用意
          let checkPrompt = "";
          // oldest_questionの初回、中身チェック
          if (this.conversations.length == 1) {
            // 0番目の中身を取得
            checkPrompt = this.conversations[0].oldest_question;
            // console.log("checkPrompt", checkPrompt);
          }

          // CosmosDBにデータが存在する場合のみ実行
          if (checkPrompt != "New Conversation") {
            // リロードされ、初めてサイドバーが表示された場合 or ゴミ箱ボタン押下時のみ最新のデータを読み込み
            if (this.isInitialize) {
              // 最新のchat_session_idを取得
              const sessionId = this.conversations_all.map((msg) => msg.chat_session_id);
              const latestSessionId = sessionId.reduce((a, b) => (a > b ? a : b));
              // console.log("latestSessionId", latestSessionId);

              // 最新のchat_session_idから最新の会話履歴を読み取り、チャット画面に表示
              this.messageReadInit(latestSessionId);

              // 初回ページ読み込み時には、一番上のゴミ箱ボタンを表示
              // (※データが存在する場合のみ)
              // 前回、表示されていたゴミ箱ボタンを非表示(セーフティー)
              this.showTrashButton[this.activeIndex] = false;
              //ゴミ箱ボタンを再表示
              this.showTrashButton[0] = true;
              this.activeIndex = 0;
              // 初回表示フラグをfalseにする
              this.$emit("initialize-false");
            } else {
              // 現在選択中の会話セッションのデータをチャット画面に出力
              this.messageReadInit(this.conversation_id);

              // console.log("conversation_id", this.conversation_id);
              // console.log("バックアップされたインデックス", this.activeindex_backup);
            }
          } else {
            // CosmosDBにデータ問い合わせ時に、
            // 会話セッションIDを初期化して、「App.vue」に渡す
            this.$emit("session-selected", "", 1);
            // activeIndexをApp.vueにバックアップとして退避(サイドバーが閉じられると、SideBar.vue側の値が初期化されるため)
            this.$emit("index-backup", 0);
            // 初回表示フラグをfalseにする(セーフティー)
            this.$emit("initialize-false");
          }
          // サイドバー「Loading」表示フラグをオフ
          this.isSideBarLoading = false;
        })
        .catch((error) => {
          alert("通信エラーが発生しました。接続先を確認してください。");

          // App.vue側でローディング画面を非表示にする
          this.$emit('loading-finished');
          // サイドバー「Loading」表示フラグをオフ
          this.isSideBarLoading = false;

          console.error("Error fetching data:", error);
        });
    },
    // CosmosDBから取得したデータの文字数監視
    checkCharacterNumber(question, index) {
      // CosmosDBにデータがない場合には、そのまま文字列を返す
      // 存在する場合には、13文字列まで表示させて後は「...」表示にする
      if (question == "New Conversation") {
        // 「New Conversation」表記時のみ、ゴミ箱ボタンを非表示(セーフティー)
        this.showTrashButton[index] = false;
        // console.log("新しい会話をスタート");

        // そのまま会話履歴なしのメッセージをフロントエンドに送る
        return question;
      } else {
        return question.length > 14 ? question.substring(0, 14) + "..." : question;
      }
    },
    // 会話履歴を抽出、App.vueに渡して画面表示(初期化)
    messageReadInit(latestSessionId) {
      // 選択されたセッションIDの会話履歴を取り出して、リストに格納
      const conversation_idhistory = this.conversations_all.filter(
        (msg) => msg.chat_session_id === latestSessionId
      );
      // console.log("会話履歴データ", conversation_idhistory);

      // 最新データの問い合わせ時に、そのデータがない場合にはCosmosDBに保存されている最新のデータを抽出して表示させる
      if (conversation_idhistory.length == 0) {
        const conversation_latesthistory = this.conversations_all.filter(
          (msg) => msg.chat_session_id === this.conversations[0].chat_session_id
        );

        // 配列の形式を指定し、親に渡すための準備を行う
        const formattedMessages = conversation_latesthistory.map((msg) => ({
          // ユーザーの質問
          question: { text: msg.question, type: "user" },
          // tsuzumiからの回答
          answer: { text: msg.answer, type: "bot" },
          // RAGから返ってきた画像URL
          imageUrl: msg.imageUrl,
          // CosmosDBから返ってきたDBデータ(グラフ描画用)
          graphData: msg.graphData,
          // CosmosDBから返ってきたグラフ種別(グラフ種別判断用)
          graphType: msg.graphType
        }));
        // console.log("履歴データなし");

        // 一番上のサイドバーボタンにおけるゴミ箱ボタンを表示させる
        this.showTrashButton[0] = true;
        //  一番上のサイドバーボタンのhoverを定義
        this.activeIndex = 0;
        // activeIndexをApp.vueにバックアップとして退避
        this.$emit("index-backup", 0);

        // フィルタリングされたデータと会話セッションIDを「App.vue」に渡す
        this.$emit(
          "session-selected",
          formattedMessages,
          this.conversations[0].chat_session_id
        );
      } else {
        // 配列の形式を指定し、親に渡すための準備を行う
        const formattedMessages = conversation_idhistory.map((msg) => ({
          // ユーザーの質問
          question: { text: msg.question, type: "user" },
          // tsuzumiからの回答
          answer: { text: msg.answer, type: "bot" },
          // RAGから返ってきた画像URL
          imageUrl: msg.imageUrl,
          // CosmosDBから返ってきたDBデータ(グラフ描画用)
          graphData: msg.graphData,
          // CosmosDBから返ってきたグラフ種別(グラフ種別判断用)
          graphType: msg.graphType
        }));
        // console.log("履歴データあり");

        // 現在選択中の会話セッションのゴミ箱ボタンを表示させる
        this.showTrashButton[this.activeindex_backup] = true;
        // 現在選択中のボタンのhoverを定義
        this.activeIndex = this.activeindex_backup;

        // フィルタリングされたデータと会話セッションIDを「App.vue」に渡す
        this.$emit("session-selected", formattedMessages, latestSessionId);
      }
    },
    // ゴミ箱ボタン表示判定フラグ(マウスオーバー時)
    showButton(zoneId) {
      // リクエストが返ってくるまでは、サイドバー側のごみ箱ボタン表示もしないようにする(セーフティー)
      if(this.loading)
      {
        // console.log("リクエスト中のため、サイドバー側のごみ箱ボタンを表示できません");
        return;
      }

      // ボタン表示判定フラグをオンにして、ゴミ箱ボタンを表示
      if (this.conversations[zoneId].oldest_question != "New Conversation") {
        this.showTrashButton[zoneId] = true;
      }
    },
    // ゴミ箱ボタン表示判定フラグ(マウスオーバーしていない時)
    hideButton(zoneId) {
      // ボタン表示判定フラグをオフにして、ゴミ箱ボタンを非表示

      // 選択されたボタンのインデックスと、マウスカーソルを合わせた時のボタンのインデックスが異なる場合のみ、
      // ゴミ箱ボタンを非表示にする(※クリックされたサイドバーボタンのゴミ箱ボタンはそのまま表示される)
      if (
        this.conversations[zoneId].oldest_question != "New Conversation" &&
        this.activeIndex != zoneId
      ) {
        this.showTrashButton[zoneId] = false;
      }
    },
    // チャット履歴削除画面を表示
    deleteItem(sessionId) {
      // ローディング中はサイドバー側のごみ箱ボタンの押下を不可能とする
      if(this.loading)
      {
        // console.log("リクエスト中のため、サイドバー側のごみ箱ボタンの押下不可能");
        return;
      }

      // 「App.vue」の@show-confirmイベントを発火させて、表示フラグをtrueにする
      this.$emit("show-confirm");

      // ゴミ箱ボタン押下時にそのボタンに割り振られているチャットセッションIDをバックアップ
      // 「削除する」ボタンが押された時に、セッションIDをバックエンドに渡す
      // それが完了したら、最新の状態にfetchする

      // 「App.vue」にsessionIdを渡す
      // 抽出されたデータと会話セッションIDを「App.vue」に渡す
      this.$emit("session-backup", sessionId);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*サイドバーの見た目を調整*/
.sidebar {
  /*ダッシュボードと同じ幅・高さを採用*/
  width: 320px;
  height: 100vh;
  background-color: rgb(65, 103, 0);
  /*固定位置に指定*/
  position: fixed;
  top: 0;
  left: 0;
  /*サイドバーをできるだけ手前に描画(値が大きいほど、手前に描画される)*/
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/*サイドバーのタイトル*/
.sidebar-title {
  /*文字の色を白に指定*/
  color: white;
  /*文字を中央寄せ*/
  text-align: center;
  /*左側の余白を3.3rem分あける*/
  margin-left: 3.3rem;
  /* 下のマージンを0に設定 */
  margin-bottom: 0;
}

/*「New Chat」ボタン*/
.newchat-button {
  /*上部・左部の余白をつけて、位置を調整(1rem = 16px)*/
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  /*上下左右の余白*/
  padding: 0.5rem 1rem;
  /*ボタンの境界線なし*/
  border: none;
  /*ボタンの角の丸具合を調整*/
  border-radius: 8px;
  /*フォントカラー*/
  color: white;
  /*フォントサイズ*/
  font-size: 17px;
  /*ボタンの色を指定*/
  background-color: rgb(54, 153, 203);
  /*カーソルを合わせた時のマウスのマークを指定*/
  cursor: pointer;
}

/*サイドバーの閉じるボタン*/
.sidebar-close-btn {
  align-self: flex-end;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: white;
}

/*余白確保エリア設定*/
.margin-area {
  height: 30px;
}

/*「New Chat」ボタン押下で新たに生成されたチャットボタン*/
.create-button {
  /*ボタンの配置を少し右寄りにする*/
  margin-left: 0.5rem;
  /*幅を指定*/
  width: 300px;
  /*上下左右の余白*/
  padding: 0.5rem 1rem;
  /*ボタンの境界線なし*/
  border: none;
  /*ボタンの角の丸具合を調整*/
  border-radius: 8px;
  /*フォントカラー*/
  color: white;
  /*フォントサイズ*/
  font-size: 17px;
  /*ボタンの色を指定*/
  background-color: rgb(65, 103, 0);
  /*カーソルを合わせた時のマウスのマークを指定*/
  cursor: pointer;
  text-align: left;
}

/*生成されたボタンにカーソルを合わせた時には色を変化させる*/
.create-button:hover {
  background-color: rgb(142, 142, 142);
}

/*生成されたボタンが押下された時の色付け設定*/
.create-button.active {
  background-color: rgb(185, 185, 185);
}

/*生成されたボタンの数によって自動でスクロールバー表示*/
.sidebar-button {
  /* 縦方向のスクロールを許可 */
  overflow-y: auto;
  width: 100%;
  overflow-x: hidden;
}

/*ゴミ箱ボタン表示設定*/
.trashicon {
  /* 絶対位置を指定 */
  position: absolute;
  /* 3px下に移動 */
  padding-top: 3px;
  /*右端からの幅を指定*/
  right: 5%;
  /*ボタンを透明に設定*/
  background-color: transparent;
  border: none;
  cursor: pointer;
}

/*ゴミ箱アイコン表示設定*/
.trash-icon {
  /*縦横の幅を指定*/
  height: 20px;
  width: 20px;
}

/*前回ログイン日時の表示設定*/
p {
  /* 上のマージンを0に設定 */
  margin-top: 0;
}

/* サイドバーのLoading表示 */
.sidebar-loading {
  color: white;
  text-align: center;
}

/*デザインA(スマートフォン)*/
/*スマートフォンサイズ、599px以下の時に適用するCSSを設定*/
@media screen and (max-width: 599px) {
  /*サイドバー側のタイトル*/
  .sidebar-title {
    /*フォントサイズをチャット画面のタイトルと同じサイズに縮小*/
    font-size: 16px;
  }

  /*前回ログイン時刻表示*/
  p {
    /*フォントサイズをチャット画面と同じサイズに縮小*/
    font-size: 15px;
  }

  /*「New Chat」ボタン*/
  .newchat-button {
    /*フォントサイズをチャット画面と同じサイズに縮小*/
    font-size: 15px;
  }

  /*サイドバーボタン*/
  .create-button {
    /*フォントサイズをチャット画面と同じサイズに縮小*/
    font-size: 15px;
  }
}

</style>
